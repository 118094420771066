import { CSSProperties } from "react";

const columns = [
  {
    id: "username",
    label: "Nombre",
    render: (value: any, record: any) => {
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            width: 160,
          }}
        >
          <h3 title={record.fullname || "-"} style={{ margin: 0 }}>
            {record.fullname || "-"}
          </h3>
          <p title={record.email || "-"} style={{ margin: 0 }}>
            {record.email || "-"}
          </p>
        </div>
      );
    },
  },
  {
    id: "datos",
    label: "Datos",
    render: (value: any, record: any) => {
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            width: 100,
          }}
        >
          <h3 title={`DNI: ${record.dni || "-"}`} style={{ margin: 0 }}>
            DNI: {record.dni || "-"}
          </h3>
          <p title={record.phone || "-"} style={{ margin: 0 }}>
            Tel: {record.phone || "-"}
          </p>
        </div>
      );
    },
  },
  {
    id: "nombreRole",
    label: "Rol",
    width: 100,
  },
  {
    id: "organizacione",
    label: "Organización",
    minWidth: 170,
    render: (value: any) => {
      if (value && value.nombre) return value.nombre;
      return <>-</>;
    },
  },
  {
    id: "active",
    label: "Estado",
    minWidth: 100,
    render: (value: any, record: any) => {
      const activeStyle: CSSProperties = {
        padding: 5,
        background: "rgba(139, 195, 74, 0.2)",
        borderRadius: 3,
        textAlign: "center",
        color: "#6BAA00",
      };
      const inactiveStyle: CSSProperties = {
        color: "#C3604A",
        background: "rgba(195, 96, 74, 0.2)",
        textAlign: "center",
        padding: 5,
        borderRadius: 3,
      };
      return (
        <div
          style={
            value && typeof value !== "string" ? activeStyle : inactiveStyle
          }
        >
          {value && typeof value !== "string" ? "Activo" : "Inactivo"}
        </div>
      );
    },
  },
  {
    id: "acciones",
    label: "Acciones",
    minWidth: 90,
  },
];

export { columns };
