import _ from "lodash";
import { findIndex } from "./helper";

const validCuitChars = [
  {
    char: "20",
  },
  { char: "23" },
  { char: "24" },
  { char: "27" },
  { char: "30" },
  { char: "33" },
  { char: "34" },
];

const validateForm = (values: any, validatorValues: any) => {
  let validate = true;
  let arrayVal = [...validatorValues];
  Object.keys(values).forEach((val: any) => {
    let index = findIndex(arrayVal, "name", val);
    if (index !== -1) {
      if (values[val]) {
        if (arrayVal[index].pattern) {
          if (val === "cuit") {
            validate = verifyCuit(values[val]);
          } else {
            validate = arrayVal[index].pattern.test(values[val]);
          }
          if (!validate) {
            throw new Error(
              `El campo ${
                arrayVal[index].nameToShow
                  ? arrayVal[index].nameToShow
                  : val.replace("Id", "")
              } ${arrayVal[index].regexMessage && arrayVal[index].regexMessage}`
            );
          }
        }
        if (_.isArray(values[val])) {
          if (values[val].length === 0 && arrayVal[index].required) {
            throw new Error(
              `El campo ${val.replace("Id", "")} se encuentra incompleto`
            );
          }
        }
      } else if (arrayVal[index].required) {
        throw new Error(
          `El campo ${
            arrayVal[index].nameToShow
              ? arrayVal[index].nameToShow
              : val.replace("Id", "")
          } es obligatorio`
        );
      }
    }
  });
  return validate;
};

const prepareToastData = async (
  response: any,
  entity: string,
  attr: string,
  determinante: string
) => {
  if ((response && response.id) || (response && response.message)) {
    const message = response.id
      ? `${determinante} ${entity} #${response[attr]} se creó de forma correcta`
      : response.message;
    return message;
  } else {
    throw new Error(response);
  }
};

const verifyCuit = (value: string) => {
  let valid = false;

  const firstChars = value.substring(0, 2);
  const index = findIndex(validCuitChars, "char", firstChars);
  if (index !== -1) {
    valid = value.length === 11;
  }
  return valid;
};

export { prepareToastData, validateForm, verifyCuit };
