import {
  alphanumericRegex,
  cuitRegex,
  dniRegex,
  emailRegex,
  numericRegex,
} from "../../../../shared/regexPatterns";
import { GridColumns } from "../../../../shared/types";

export const resetData = {
  nombre: "",
  nombreLegal: "",
  cuit: "",
  telefono: "",
  correo: "",
  habilitarCadenaBloques: 0,
  seguimientoProsegur: 0,
  calle: "",
  numero: "",
  piso: "",
  departamento: "",
  codigoPostal: "",
  provinciaId: "",
  ciudadId: "",
  localidadId: "",
};

export const validatorValues = [
  {
    name: "nombre",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "nombreLegal",
    nameToShow: "nombre legal",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "cuit",
    required: true,
    pattern: cuitRegex,
    regexMessage: "no es valido",
  },
  {
    name: "telefono",
    pattern: numericRegex,
    required: false,
    regexMessage: "solo acepta valores numericos",
  },
  {
    name: "correo",
    pattern: emailRegex,
    required: true,
    regexMessage: "solo acepta formato de email. Ej: example@gmail.com",
  },
  {
    name: "calle",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "numero",
    pattern: numericRegex,
    required: true,
    regexMessage: "solo acepta valores numericos",
  },
  {
    name: "piso",
    required: false,
    pattern: numericRegex,
    regexMessage: "solo acepta valores numericos",
  },
  { name: "departamento", required: false },
  { name: "codigoPostal", required: false },
  { name: "provinciaId", required: true },
  { name: "ciudadId", required: true },
  { name: "localidadId", required: true },
  { name: "habilitarCadenaBloques", required: false },
  { name: "seguimientoProsegur", required: false },
];

export const validatorValuesUser = [
  { name: "organizacionId", nameToShow: "organizacion", required: true },
  {
    name: "fullname",
    nameToShow: "nombre y apellido",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "phone",
    nameToShow: "telefono",
    pattern: numericRegex,
    required: false,
    regexMessage: "solo acepta valores numericos",
  },
  {
    name: "email",
    pattern: emailRegex,
    required: true,
    regexMessage: "solo acepta formato de email. Ej: example@gmail.com",
  },
  { name: "rolId", nameToShow: "rol", required: true },
  {
    name: "dni",
    pattern: dniRegex,
    required: true,
    regexMessage:
      "solo acepta formato de dni sin espacios ni puntos. Ej: 27845745",
  },
  { name: "active", required: false },
];

export const validatorValuesEstablishment = [
  {
    name: "organizacionId",
    nameToShow: "organizacion",
    required: false,
  },
  {
    name: "companiaId",
    nameToShow: "compania",
    required: false,
  },
  {
    name: "nombre",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios.",
  },
  {
    name: "longitud",
    required: false,
  },
  {
    name: "latitud",
    required: false,
  },
  {
    name: "provinciaId",
    nameToShow: "provincia",
    required: true,
  },
  {
    name: "ciudadId",
    nameToShow: "ciudad",
    required: true,
  },
  {
    name: "localidadId",
    nameToShow: "localidad",
    required: true,
  },
];

export const validatorValuesCompany = [
  {
    name: "nombre",
    nameToShow: "nombre",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "nombreLegal",
    nameToShow: "nombre legal",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios.",
  },
  {
    name: "cuit",
    pattern: cuitRegex,
    required: true,
    regexMessage:
      "solo acepta formato de cuit: Ej: 20-39.845.612-1 sin puntos ni barras.",
  },
  {
    name: "correo",
    pattern: emailRegex,
    required: true,
    regexMessage: "solo acepta formato de email. Ej: example@gmail.com",
  },
  {
    name: "telefono",
    pattern: numericRegex,
    required: false,
    regexMessage: "solo acepta formato numerico.",
  },
  {
    name: "calle",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "numero",
    pattern: numericRegex,
    required: true,
    regexMessage: "solo acepta formato numerico.",
  },
  {
    name: "piso",
    pattern: numericRegex,
    regexMessage: "solo acepta formato numerico.",
    required: false,
  },
  {
    name: "departamento",
    required: false,
  },
  {
    name: "codigoPostal",
    nameToShow: "codigo postal",
    required: false,
  },
  {
    name: "provinciaId",
    nameToShow: "provincia",
    required: true,
  },
  {
    name: "ciudadId",
    nameToShow: "ciudad",
    required: true,
  },
  {
    name: "localidadId",
    nameToShow: "localidad",
    required: true,
  },
];

export const companiesColumns: GridColumns[] = [
  { id: "nombre", label: "Nombre", width: 100 },
  { id: "cuit", label: "CUIT", width: 100 },
  {
    id: "provinciaId",
    label: "Provincia",
    render: (value: any, record: any) => (
      <div style={{ width: 100 }} className="wrap">
        {record.provinciaName || "-"}
      </div>
    ),
  },
  { id: "acciones", label: "Acciones" },
];

export const devicesColumns: GridColumns[] = [
  { id: "id", label: "ID" },
  {
    id: "tipoId",
    label: "Tipo",
    render: (value: any, record: any) => {
      return (
        <div style={{ width: 90 }} className="wrap">
          {record.dispositivo_tipo && record.dispositivo_tipo.nombre}
        </div>
      );
    },
    width: 90,
  },
  { id: "nombre", label: "Nombre", width: 110 },
  {
    id: "estadoId",
    label: "Estado",
    render: (value: any, record: any) => {
      return record.dispositivo_estado && record.dispositivo_estado.nombre;
    },
  },
  { id: "acciones", label: "Acciones" },
];

export const usersColumns = (companiesList: any[]): GridColumns[] => [
  {
    id: "name",
    label: "Nombre",
    render: (value: any, record: any) => {
      return (
        <div style={{ display: "flex", flexDirection: "column", width: 160 }}>
          <b title={record.fullname || "-"}>{record.fullname || "-"}</b>
          <p title={record.email || "-"} style={{ margin: 0 }}>
            {record.email || "-"}
          </p>
        </div>
      );
    },
  },
  {
    id: "datos",
    label: "Datos",
    render: (value: any, record: any) => {
      return (
        <div style={{ display: "flex", flexDirection: "column", width: 110 }}>
          <b title={`DNI: ${record.dni || "-"}`}>DNI: {record.dni || "-"}</b>
          <p title={record.phone || "-"} style={{ margin: 0 }}>
            {record.phone || "-"}
          </p>
        </div>
      );
    },
  },
  {
    id: "compania",
    label: "Compañía",
    render: (value: any, record: any) => {
      const organizacion = companiesList.find(
        (val) => val.id === record.companiaId
      );
      return (
        <div
          style={{ width: 130 }}
          title={(organizacion && organizacion.name) || "-"}
        >
          {(organizacion && organizacion.name) || "-"}
        </div>
      );
    },
  },
  {
    id: "rol",
    label: "Rol",
    render: (value: any, record: any) => {
      return (
        <div
          style={{ width: 130 }}
          title={(record.role && record.role.nombre) || "-"}
        >
          {(record.role && record.role.nombre) || "-"}
        </div>
      );
    },
  },
  { id: "acciones", label: "Acciones" },
];

export const establishmentsColumns = (companiesList: any[]): GridColumns[] => [
  { id: "nombre", label: "Nombre", width: 160 },
  {
    id: "companiaId",
    label: "Compañía",
    render: (value: any, record: any) => {
      const organizacion = companiesList.find(
        (val) => val.id === record.companiaId
      );
      return (
        <div
          style={{ width: 100 }}
          className="wrap"
          title={(organizacion && organizacion.nombre) || "-"}
        >
          {(organizacion && organizacion.nombre) || "-"}
        </div>
      );
    },
    width: 100,
  },
  {
    id: "provinciaId",
    label: "Provincia",
    render: (value: any, record: any) => (
      <div
        style={{ width: 100 }}
        className="wrap"
        title={(record.provincium && record.provincium.nombre) || "-"}
      >
        {(record.provincium && record.provincium.nombre) || "-"}
      </div>
    ),
  },
  { id: "acciones", label: "Acciones" },
];

export const formatEstablishmentValues = (values: any) => {
  const {
    companiaId,
    nombre,
    longitud,
    latitud,
    provinciaId,
    ciudadId,
    localidadId,
  } = values;
  return {
    companiaId,
    nombre,
    longitud,
    latitud,
    provinciaId,
    ciudadId,
    localidadId,
  };
};

export const formatOrganizationValues = (values: any) => {
  const {
    nombre,
    nombreLegal,
    cuit,
    telefono,
    correo,
    habilitarCadenaBloques,
    seguimientoProsegur,
    calle,
    piso,
    numero,
    ciudadId,
    departamento,
    localidadId,
    provinciaId,
    codigoPostal,
  } = values;
  return {
    nombre,
    nombreLegal,
    cuit,
    telefono,
    correo,
    habilitarCadenaBloques,
    seguimientoProsegur,
    domicilio: {
      calle,
      numero,
      piso,
      departamento,
      codigoPostal,
      provinciaId,
      ciudadId,
      localidadId,
    },
  };
};

export const formatCompanyValues = (values: any) => {
  const {
    nombre,
    nombreLegal,
    cuit,
    telefono,
    correo,
    habilitarCadenaBloques,
    seguimientoProsegur,
    calle,
    numero,
    piso,
    departamento,
    codigoPostal,
    provinciaId,
    ciudadId,
    localidadId,
    organizacionId,
  } = values;
  return {
    nombre,
    nombreLegal,
    cuit,
    telefono,
    correo,
    habilitarCadenaBloques,
    seguimientoProsegur,
    domicilio: {
      calle,
      numero,
      piso,
      departamento,
      codigoPostal,
      provinciaId,
      ciudadId,
      localidadId,
    },
    organizacionId,
  };
};
