import * as types from "./actionTypes";

export const getHarvestsListDataAction = () => {
  return {
    type: types.GET_HARVESTS_LIST_DATA,
  };
};

export const getHarvestsListDataFail = (error: any) => {
  return {
    type: types.GET_HARVESTS_LIST_DATA_FAIL,
    payload: error,
  };
};

export const getHarvestsListDataSuccess = (harvestsList: any) => {
  return {
    type: types.GET_HARVESTS_LIST_DATA_SUCCESS,
    payload: harvestsList,
  };
};
