import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  alphanumericRegex,
  numericRegex,
} from "../../../../shared/regexPatterns";
import { ReduxType } from "../../../../shared/types";
import {
  getCitiesListDataAction,
  getLocalitiesListDataAction,
  getProvincesListDataAction,
} from "../../../../store/organizations/actions";

import { CustomTextField } from "../../../formInputs";
import { StyledSelect } from "../../../formInputs/SelectComponent/StyledSelect";

export interface CompanyFormGridProps {
  onChange: ({ value, name }: { value: any; name: string }) => void;
  editionMode: boolean;
  organizations: any[];
  organizationId?: any;
  formValues: any;
}

const EstablishmentFormGridComponent = (props: CompanyFormGridProps) => {
  const { onChange, formValues, editionMode, organizationId, organizations } =
    props;
  const dispatch = useDispatch();

  const { organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  React.useEffect(() => {
    getProvince();
  }, []);

  const getProvince = async () => {
    await dispatch(getProvincesListDataAction(formValues ? false : true));
    formValues &&
      formValues.ciudadId &&
      (await dispatch(getLocalitiesListDataAction(formValues.ciudadId)));
  };

  const getOnChange = async (name: string, id: any) => {
    switch (name) {
      case "provinciaId":
        await dispatch(getCitiesListDataAction(id, true));
        onChange && onChange({ name: "provinciaId", value: id });
        break;
      case "ciudadId":
        await dispatch(getLocalitiesListDataAction(id));
        onChange && onChange({ name: "ciudadId", value: id });
        break;
      case "localidadId":
        onChange && onChange({ name: "localidadId", value: id });
        break;
      default:
        break;
    }
  };

  const onDomicilioChange = ({ value, name }: { value: any; name: string }) => {
    getOnChange(name, value.target.value);
  };
  return (
    <form onSubmit={() => {}}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="provincia-select-label">Organización</InputLabel>
            <StyledSelect
              labelId="provincia-select-label"
              id="organizacionId"
              name="organizacionId"
              defaultValue={
                formValues.organizacionId ? formValues.organizacionId : ""
              }
              onChange={(e) =>
                onChange({ value: e.target.value, name: "organizacionId" })
              }
              disabled={!editionMode || !!organizationId}
            >
              {organizations &&
                organizations.map((o, index: number) => {
                  return (
                    <MenuItem key={`index-${index}`} value={o.id}>
                      {o.nombre}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="provincia-select-label">Compañia</InputLabel>
            <StyledSelect
              labelId="provincia-select-label"
              id="companiaId"
              name="companiaId"
              value={formValues.companiaId ? formValues.companiaId : ""}
              onChange={(e) =>
                onChange({ value: e.target.value, name: "companiaId" })
              }
              disabled={!editionMode}
            >
              {organizationsData.companiesList &&
                organizationsData.companiesList.map((o, index: number) => {
                  return (
                    <MenuItem key={`index-${index}`} value={o.id}>
                      {o.nombre}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            id="nombre"
            variant="filled"
            name="nombre"
            pattern={alphanumericRegex}
            required
            style={{ width: "100%" }}
            defaultValue={formValues.nombre}
            onChange={onChange}
            label="Nombre"
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="provincia-select-label">Provincia *</InputLabel>
            <StyledSelect
              labelId="provincia-select-label"
              id="provinciaId"
              name="provinciaId"
              required
              defaultValue={
                formValues.provinciaId ? formValues.provinciaId : ""
              }
              disabled={!editionMode}
              onChange={(e) =>
                onDomicilioChange({ value: e, name: "provinciaId" })
              }
            >
              {organizationsData.provinces &&
                organizationsData.provinces.length > 0 &&
                organizationsData.provinces.map((value: any) => {
                  return <MenuItem value={value.id}>{value.nombre}</MenuItem>;
                })}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="ciudad-select-label">Ciudad *</InputLabel>
            <StyledSelect
              labelId="ciudad-select-label"
              id="ciudadId"
              name="ciudadId"
              defaultValue={formValues.ciudadId ? formValues.ciudadId : ""}
              disabled={!editionMode}
              required
              onChange={(e) =>
                onDomicilioChange({ value: e, name: "ciudadId" })
              }
            >
              {organizationsData.cities &&
                organizationsData.cities.length > 0 &&
                organizationsData.cities.map((value: any) => {
                  return <MenuItem value={value.id}>{value.nombre}</MenuItem>;
                })}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="localidad-select-label">Localidad *</InputLabel>
            <StyledSelect
              labelId="localidad-select-label"
              id="localidadId"
              name="localidadId"
              required
              defaultValue={
                formValues.localidadId ? formValues.localidadId : ""
              }
              disabled={!editionMode}
              onChange={(e) =>
                onDomicilioChange({ value: e, name: "localidadId" })
              }
            >
              {organizationsData &&
                organizationsData.localities.length > 0 &&
                organizationsData.localities.map((value: any) => {
                  return <MenuItem value={value.id}>{value.nombre}</MenuItem>;
                })}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={6} lg={3}>
          <CustomTextField
            id="longitud"
            label="Longitud"
            name="longitud"
            pattern={numericRegex}
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.longitud}
            onChange={onChange}
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={6} lg={3}>
          <CustomTextField
            id="latitud"
            label="Latitud"
            name="latitud"
            pattern={numericRegex}
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.latitud}
            onChange={onChange}
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={6} lg={6}>
          <Grid container alignItems={"center"}>
            <Grid item xs={6}>
              Posicion geográfica
            </Grid>
            <Grid item xs={6}>
              <Button
                className="login-button ligth-green "
                style={{ margin: "auto" }}
                variant="contained"
              >
                VER MAPA
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EstablishmentFormGridComponent;
