import React from "react";

import VIEW from "../../../assets/icons/svg/GridActions/view.svg";
import EDIT from "../../../assets/icons/svg/GridActions/edit.svg";
import DELETE from "../../../assets/icons/svg/GridActions/delete.svg";

interface GridActionsProps {
  data: any;
  actions: { edit: boolean; view: boolean; delete: boolean };
  align?: "center" | "right";
  onClick?: (value: "VIEW" | "EDIT" | "DELETE") => void;
}

const GridActions = ({ data, onClick, actions, align }: GridActionsProps) => {
  return (
    <div
      className="grid-actions"
      style={{
        justifyContent: !!align
          ? align === "right"
            ? "flex-end"
            : align === "center"
            ? "center"
            : ""
          : "unset",
      }}
    >
      {actions.view && (
        <img
          src={VIEW}
          alt="view-icon"
          onClick={() => onClick && onClick("VIEW")}
          title="View"
        />
      )}
      {actions.edit && (
        <img
          src={EDIT}
          alt="edit-icon"
          onClick={() => onClick && onClick("EDIT")}
          title="Edit"
        />
      )}
      {actions.delete && (
        <img
          src={DELETE}
          alt="delete-icon"
          onClick={() => onClick && onClick("DELETE")}
          title="Delete"
        />
      )}
    </div>
  );
};
export default GridActions;
