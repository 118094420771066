export const Endpoints = {
  LOGIN: "login",
  PROFILE: "start",
  DASHBOARD: "dashboard_backoffice_data",
  USERS: {
    GET_LIST: "users",
    CREATE: "api/users",
    UPDATE: "api/users",
  },
  HARVESTS: "harvests",
  COMPANIES: "companies",
  ESTABLISHMENTS: "establishments",
  ORGANIZATIONS: {
    GET_LIST: "organization",
    GET: "organization/getOrganizationData",
    CREATE: "api/organization",
    UPDATE: "api/organization",
  },
  ROLES: {
    GET_LIST: "roles",
    GET_PERMISSIONS_LIST: "permissions",
    PUT_PERMISSION: "api/users/userPermission",
  },
  HELPERS: {
    LOCALITY: "locality",
    CITY: "city",
    PROVINCE: "province",
  },
  DEVICES: {
    GET_LIST: "device",
    GET_UNLINK_LIST: "device/unlinkDevices",
    CREATE: "api/device",
    UPDATE: "api/device",
    LINK_UNLINK: "api/device/linkOrUnlink",
    FIND_BY_TYPE: "api/device/findByType",
  },
};
