import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomLinkProps, ReduxType } from "../../../shared/types";
import {
  deleteRolAction,
  getRolesListAction,
} from "../../../store/roles/actions";
import { ToastComponent } from "../../toast";
import { GenericGrid } from "../genericGrid";
import { NewRoleComponent } from "../newRole";

const columns = [
  {
    id: "nombre",
    label: "Rol",
    minWidth: 400,
  },
  {
    id: "rol_permisos",
    label: "Permisos",
    minWidth: 100,
    render: (value: any) => (
      <>
        {value && _.isArray(value)
          ? value.filter((val) => val.permiso && val.permiso.nombre).length
          : 0}
      </>
    ),
  },
  {
    id: "acciones",
    label: "Acciones",
    minWidth: 100,
  },
];

const RoleListComponent = (props: any) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [editionMode, setEditionMode] = useState<any>(false);
  const [createMode, setCreateMode] = useState<any>(false);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const [rolToEditOrView, setRolToEditOrView] = useState();

  const { rolesData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (!rolesData.rolesList || rolesData.rolesList.length === 0)
      getRolesList();
  }, []);

  const getRolesList = async () => {
    await dispatch(getRolesListAction());
  };

  const headerButtons: CustomLinkProps[] = [
    {
      id: "newRol",
      label: "NUEVO ROL",
      type: "SIMPLE",
      className: "login-button ligth-green",
      variant: "contained",

      onClick: () => {
        setEditionMode(true);
        setCreateMode(true);
        setOpen(!open);
      },
    },
  ];

  const closeAndResetData = async (message?: any) => {
    await setRolToEditOrView(undefined);
    await setEditionMode(false);
    await setCreateMode(false);
    await setOpen(!open);
    if (message) {
      setErrorMessage(message);
      setShowToast(true);
    }
    await getRolesList();
  };

  const action = async (action: "VIEW" | "DELETE" | "EDIT", row: any) => {
    switch (action) {
      case "EDIT":
        await setRolToEditOrView(row);
        setEditionMode(true);
        setOpen(!open);
        break;
      case "VIEW":
        await setRolToEditOrView(row);
        await setOpen(!open);
        break;
      case "DELETE":
        await dispatch(deleteRolAction(row.id));
        setErrorMessage("Rol eliminado exitosamente");
        setShowToast(true);
        setTimeout(async () => {
          await getRolesList();
        }, 500);
        break;
    }
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setErrorMessage(undefined);
  };

  const searchCallback = (val: any, value: any) => {
    return val.nombre && val.nombre.toUpperCase().indexOf(value) > -1;
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={false}
        bodyContent={errorMessage}
      />

      {open && (
        <NewRoleComponent
          open={open}
          rolToEditOrView={rolToEditOrView}
          editionMode={editionMode}
          createMode={createMode}
          setEditionMode={setEditionMode}
          handleClose={(message?: any) => closeAndResetData(message)}
        />
      )}

      <GenericGrid
        headerButtons={headerButtons}
        columns={columns}
        displayData={rolesData.rolesList}
        header={true}
        searchCallback={searchCallback}
        hasSearchInput={true}
        onActionClick={action}
        actions={{
          view: true,
          delete: true,
          edit: true,
        }}
      />
    </>
  );
};

export default RoleListComponent;
