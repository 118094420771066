import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../config/paths";
import { AuthenticatedRouteProps, ReduxType } from "../../shared/types";
import { isLoggedIn } from "../auth/AuthService";
import LoginLayaout from "../layaout/LoginLayaout";
import MainLayaout from "../layaout/MainLayaout";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { isCRUDdisabled } from "../../shared/screenAuth";
import { getUsersListAction } from "../../store/users/actions";
import { getPermissionsListAction } from "../../store/roles/actions";
import { genericRequest } from "../../shared/ApiService";
import { Endpoints } from "../../config/endpoints";

const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
  const { children, loading, path } = props;

  const [debounceValue, setDebounceValue] = useState(0);
  const [localLoading, setLocalLoading] = useState(true);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { userData, rolesData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (!isLoggedIn() && path !== Paths.LOGIN) {
      return navigate(Paths.LOGIN);
    }
    setLocalLoading(false);
  }, [path]);

  useEffect(() => {
    setDebounceValue(debounceValue + 1);
    if (!rolesData.permissionsList) {
      getPermissionsList();
    }
    if (!userData.usersList) {
      getUsersList();
    }
    isUrlAvailable();
  }, [userData.usersList, rolesData.permissionsList, path]);

  const getUsersAndPermissions = async () => {
    try {
      setLocalLoading(true);
      const users = await genericRequest(
        "api",
        Endpoints.USERS.GET_LIST,
        "GET",
        {},
        () => {}
      );
      const permissions = await genericRequest(
        "api",
        Endpoints.ROLES.GET_PERMISSIONS_LIST,
        "GET",
        {},
        () => {}
      );
      setLocalLoading(false);
      return { users, permissions };
    } catch (error: any) {
      console.error(error);
    }
  };

  const isUrlAvailable = async () => {
    if (path === Paths.NO_PERMISSIONS) return;
    const values: any = await getUsersAndPermissions();
    if (!values) return navigate(Paths.NO_PERMISSIONS);
    if (
      path !== Paths.NO_PERMISSIONS &&
      (userData.userProfile || values?.permissions)
    ) {
      if (!userData.userProfile) return;
      const userProfile =
        typeof userData.userProfile === "string"
          ? JSON.parse(userData.userProfile).username
          : userData.userProfile.username;
      const isEnabled = isCRUDdisabled(
        values.users,
        values.permissions,
        userProfile,
        path
      );
      if (!isEnabled) {
        return navigate(Paths.NO_PERMISSIONS);
      }
    }
  };

  const getUsersList = async () => {
    await dispatch(getUsersListAction());
  };

  const getPermissionsList = async () => {
    await dispatch(getPermissionsListAction());
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const getChildrenOrSpinner = () => {
    return (
      <>
        {!localLoading && !loading ? (
          <>{children}</>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{ width: 64, height: 64 }}
                className="color-green"
              />
            </Box>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {path === Paths.LOGIN ? (
        <LoginLayaout {...props} width={width}>
          {getChildrenOrSpinner()}
        </LoginLayaout>
      ) : (
        <MainLayaout {...props} width={width}>
          {getChildrenOrSpinner()}
        </MainLayaout>
      )}
    </>
  );
};

export default AuthenticatedRoute;
