import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL } from "../../config/general-config";
import {
  apiDelete,
  apiPost,
  apiPut,
  genericRequest,
} from "../../shared/ApiService";
import { ApiResponse } from "../../shared/types";
import {
  getPermissionsListSuccess,
  getPermissionsListFail,
  getRolesListFail,
  getRolesListSuccess,
  createRolSuccess,
  createRolFail,
  updateRolSuccess,
  updateRolFail,
  deleteRolSuccess,
  deleteRolFail,
} from "./actions";
import {
  CREATE_ROL,
  DELETE_ROL,
  GET_PERMISSIONS_LIST,
  GET_ROLES_LIST,
  UPDATE_ROL,
} from "./actionTypes";

function* onGetPermissionsList() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        genericRequest(
          "api",
          Endpoints.ROLES.GET_PERMISSIONS_LIST,
          "GET",
          {},
          () => {}
        )
      );
    });
    yield put(getPermissionsListSuccess(response));
  } catch (error) {
    yield put(getPermissionsListFail(error));
  }
}

function* onGetRolesList() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        genericRequest("api", Endpoints.ROLES.GET_LIST, "GET", {}, () => {})
      );
    });
    yield put(getRolesListSuccess(response));
  } catch (error) {
    yield put(getRolesListFail(error));
  }
}

function* onCreateRol(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiPost({
          url: `${API_URL}/api/${Endpoints.ROLES.GET_LIST}`,
          body: action.payload,
        })
      );
    });
    yield put(createRolSuccess(response));
  } catch (error) {
    yield put(createRolFail(error));
  }
}

function* onUpdateRol(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiPut({
          url: `${API_URL}/api/${Endpoints.ROLES.GET_LIST}/${action.rolId}`,
          body: action.payload,
        })
      );
    });
    yield put(updateRolSuccess(response));
  } catch (error) {
    yield put(updateRolFail(error));
  }
}

function* onDeleteRol(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiDelete({
          url: `${API_URL}/api/${Endpoints.ROLES.GET_LIST}/${action.rolId}`,
        })
      );
    });
    yield put(deleteRolSuccess(response));
  } catch (error) {
    yield put(deleteRolFail(error));
  }
}

function* RolesSaga() {
  yield takeLatest(GET_ROLES_LIST, onGetRolesList);
  yield takeLatest(GET_PERMISSIONS_LIST, onGetPermissionsList);
  yield takeLatest(CREATE_ROL, onCreateRol);
  yield takeLatest(UPDATE_ROL, onUpdateRol);
  yield takeLatest(DELETE_ROL, onDeleteRol);
}

export default RolesSaga;
