import { Divider, Grid } from "@mui/material";
import React from "react";
import { CustomButton } from "../../../customButton";
import { GenericGrid } from "../../genericGrid";
import {
  devicesColumns,
  establishmentsColumns,
  usersColumns,
} from "../constants/Constants";

interface Props {
  usersList: any[];
  establishmentsList: any[];
  devicesList: any[];
  handleModalOpen: (
    name: "company" | "device" | "establishment" | "user",
    mode: "create" | "edit" | "view" | "link",
    initialValues?: any
  ) => Promise<void>;
  companiesList: any[];
}

export const RightColumn = (props: Props) => {
  const {
    usersList,
    establishmentsList,
    devicesList,
    handleModalOpen,
    companiesList,
  } = props;
  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          className="white"
          style={{ padding: "10px 17px" }}
          rowGap={1}
        >
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={8}>
                  <h3 className="w-500" style={{ wordBreak: "break-all" }}>
                    Usuarios
                  </h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomButton
                    key={"USER-BUTTON"}
                    id={"USER-BUTTON"}
                    label="USUARIO"
                    type="SIMPLE"
                    startAdornment
                    onClick={() => handleModalOpen("user", "create")}
                    style={{ float: "right" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} textAlign="center">
              {usersList && usersList.length > 0 ? (
                <GenericGrid
                  style={{ padding: 0 }}
                  columns={usersColumns(usersList)}
                  displayData={usersList}
                  rowsPerPage={5}
                  onActionClick={(action: any, record) =>
                    handleModalOpen("user", action.toLowerCase(), record)
                  }
                  actions={{
                    view: true,
                    delete: false,
                    edit: true,
                  }}
                />
              ) : (
                "Sin usuarios creados"
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "10px 0" }} rowGap={1}>
            <Divider light />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={8}>
                  <h3 className="w-500" style={{ wordBreak: "break-all" }}>
                    Establecimientos
                  </h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomButton
                    key={"ESTABLISHMENT-BUTTON"}
                    id={"ESTABLISHMENT-BUTTON"}
                    label="ESTABLECIMIENTO"
                    onClick={() => handleModalOpen("establishment", "create")}
                    type="SIMPLE"
                    title="Nuevo Establecimiento"
                    startAdornment
                    style={{ float: "right" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} textAlign="center">
              {establishmentsList && establishmentsList.length > 0 ? (
                <GenericGrid
                  style={{ padding: 0 }}
                  columns={establishmentsColumns(companiesList)}
                  displayData={establishmentsList}
                  rowsPerPage={5}
                  onActionClick={(action: any, record) =>
                    handleModalOpen(
                      "establishment",
                      action.toLowerCase(),
                      record
                    )
                  }
                  actions={{
                    view: true,
                    delete: false,
                    edit: true,
                  }}
                />
              ) : (
                "Sin establecimientos creados"
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          className="white"
          style={{ padding: "10px 17px" }}
          rowGap={1}
        >
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item sm={12} md={8}>
                <h3 className="w-500" style={{ wordBreak: "break-all" }}>
                  Dispositivos
                </h3>
              </Grid>
              <Grid item sm={12} md={4}>
                <CustomButton
                  key={"DISPOSITIVO-BUTTON"}
                  id={"DISPOSITIVO-BUTTON"}
                  label="DISPOSITIVO"
                  type="SELECT"
                  startAdornment
                  title="Dispositivo"
                  style={{ float: "right" }}
                  items={[
                    {
                      value: 10,
                      label: "Nuevo",
                      onClick: () => handleModalOpen("device", "create"),
                    },
                    {
                      value: 5,
                      label: "Vincular",
                      onClick: () => handleModalOpen("device", "link"),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="center">
            {devicesList && devicesList.length > 0 ? (
              <GenericGrid
                style={{ padding: 0 }}
                columns={devicesColumns}
                displayData={devicesList}
                rowsPerPage={5}
                onActionClick={(action: any, record) =>
                  handleModalOpen("device", action.toLowerCase(), record)
                }
                actions={{
                  view: true,
                  delete: false,
                  edit: true,
                }}
              />
            ) : (
              "Sin dispositivos asociados"
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
