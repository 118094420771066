import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomLinkProps, ReduxType } from "../../../shared/types";
import {
  deleteUserAction,
  getUsersListAction,
} from "../../../store/users/actions";
import { ToastComponent } from "../../toast";
import { GenericGrid } from "../genericGrid";
import { NewUserComponent } from "../newUser";
import { columns } from "./Columns";

const UserListComponent = (props: any) => {
  const [open, setOpen] = useState(false);
  const [editionMode, setEditionMode] = useState<any>(false);
  const [createMode, setCreateMode] = useState<any>(false);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const [userToEditOrView, setUserToEditOrView] = useState();

  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userData.usersList) {
      getUsersList();
    }
  }, []);

  const getUsersList = async () => {
    await dispatch(getUsersListAction());
  };

  const headerButtons: CustomLinkProps[] = [
    {
      id: "newUser",
      label: "NUEVO USUARIO",
      type: "SIMPLE",
      className: "login-button ligth-green",
      variant: "contained",
      onClick: () => {
        setEditionMode(true);
        setCreateMode(true);
        setOpen(true);
      },
    },
  ];

  const closeAndResetData = async (user: any) => {
    if (user !== "cancel" && !user.cancelable) {
      setMessage(user);
      setShowToast(true);
    }
    setUserToEditOrView(undefined);
    setOpen(false);
    setTimeout(async () => {
      await getUsersList();
    }, 500);
  };

  const action = async (action: "VIEW" | "DELETE" | "EDIT", row: any) => {
    switch (action) {
      case "EDIT":
        await setUserToEditOrView(row);
        setEditionMode(true);
        setOpen(!open);
        break;
      case "VIEW":
        await setUserToEditOrView(row);
        await setOpen(!open);
        break;
      case "DELETE":
        await dispatch(deleteUserAction(row.id));
        setMessage("Usuario eliminado exitosamente");
        setShowToast(true);
        setTimeout(async () => {
          await getUsersList();
        }, 500);
        break;
    }
  };

  const cleanAndClose = async () => {
    await setShowToast(false);
    await setMessage(undefined);
  };

  const searchCallback = (val: any, value: any) => {
    return (
      val.id === value ||
      (val.organizacione &&
        val.organizacione.name &&
        val.organizacione.name.toUpperCase().indexOf(value) > -1) ||
      (val.dni && val.dni.toUpperCase().indexOf(value) > -1) ||
      (val.role &&
        val.role.name &&
        val.role.name.toUpperCase().indexOf(value) > -1) ||
      (val.fullname && val.fullname.toUpperCase().indexOf(value) > -1)
    );
  };

  return (
    <>
      {open && (
        <NewUserComponent
          open={open}
          userToEditOrView={userToEditOrView}
          editionMode={editionMode}
          createMode={createMode}
          setEditionMode={setEditionMode}
          handleClose={(user: any) => closeAndResetData(user)}
        />
      )}

      <ToastComponent
        showToast={showToast}
        toggleShow={cleanAndClose}
        errorType={false}
        bodyContent={message}
      />

      <GenericGrid
        headerButtons={headerButtons}
        columns={columns}
        displayData={userData.usersList}
        header={true}
        searchCallback={searchCallback}
        hasSearchInput={true}
        onActionClick={action}
        actions={{
          view: true,
          delete: true,
          edit: true,
        }}
      />
    </>
  );
};

export default UserListComponent;
