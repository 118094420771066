export const GET_ROLES_LIST = "GET_ROLES_LIST";
export const GET_ROLES_LIST_FAIL = "GET_ROLES_LIST_FAIL";
export const GET_ROLES_LIST_SUCCESS = "GET_ROLES_LIST_SUCCESS";
export const GET_PERMISSIONS_LIST = "GET_PERMISSIONS_LIST";
export const GET_PERMISSIONS_LIST_FAIL = "GET_PERMISSIONS_LIST_FAIL";
export const GET_PERMISSIONS_LIST_SUCCESS = "GET_PERMISSIONS_LIST_SUCCESS";
export const CREATE_ROL = "CREATE_ROL";
export const CREATE_ROL_FAIL = "CREATE_ROL_FAIL";
export const CREATE_ROL_SUCCESS = "CREATE_ROL_SUCCESS";
export const UPDATE_ROL = "UPDATE_ROL";
export const UPDATE_ROL_FAIL = "UPDATE_ROL_FAIL";
export const UPDATE_ROL_SUCCESS = "UPDATE_ROL_SUCCESS";
export const DELETE_ROL = "DELETE_ROL";
export const DELETE_ROL_FAIL = "DELETE_ROL_FAIL";
export const DELETE_ROL_SUCCESS = "DELETE_ROL_SUCCESS";
