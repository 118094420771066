import { Button, Divider, Grid, Modal } from "@mui/material";

import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Endpoints } from "../../../config/endpoints";
import { API_URL } from "../../../config/general-config";
import { apiPost, apiPut } from "../../../shared/ApiService";
import { newRoleFormValues } from "../../../shared/FormValues";
import { ReduxType } from "../../../shared/types";
import { prepareToastData, validateForm } from "../../../shared/validations";
import { getPermissionsListAction } from "../../../store/roles/actions";
import { ToastComponent } from "../../toast";
import { LayoutGridBoxComponent } from "../layoutGridBox";
import { validatorValues } from "./constants/Constants";
import { RolFormGridComponent } from "./rolFormGrid";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -60%)",
  width: 800,
  backgroundColor: "#FFFFFF",
};

const NewRoleComponent = (props: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<any>(newRoleFormValues);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const dispatch = useDispatch();

  const { rolesData } = useSelector((state: ReduxType) => {
    return state;
  });

  const {
    createMode,
    rolToEditOrView,
    editionMode,
    handleClose,
    open,
    setEditionMode,
  } = props;

  useEffect(() => {
    if (!createMode && rolToEditOrView) {
      initialUpdate(rolToEditOrView, false);
    }
    if (createMode && editionMode) {
      initialUpdate(newRoleFormValues, false);
    }
  }, [createMode, editionMode, rolToEditOrView]);

  useEffect(() => {
    if (!rolesData.permissionsList) {
      getAllRolData();
    }
  }, []);

  const getAllRolData = async () => {
    await dispatch(getPermissionsListAction());
  };

  const onChange = ({ value, name }: any) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const initialUpdate = async (rolToEditOrView: any, loading: boolean) => {
    const formatRoles =
      rolToEditOrView &&
      rolToEditOrView.rol_permisos.length > 0 &&
      rolToEditOrView.rol_permisos[0].hasOwnProperty("permiso")
        ? rolToEditOrView.rol_permisos.map(
            (val: any) => val.permiso && val.permiso.id
          )
        : rolToEditOrView.rol_permisos;
    await setFormValues({
      nombre: rolToEditOrView.nombre,
      rol_permisos: formatRoles,
    });
    await setLoading(loading);
  };

  const onSubmit = async () => {
    try {
      if (!validateForm(formValues, validatorValues)) {
        throw new Error(
          "Complete todos los datos obligatorios antes de continuar."
        );
      }
      const body = {
        nombre: formValues.nombre,
        permissions: formValues.rol_permisos,
      };
      let response;
      if (createMode) {
        response = await apiPost({
          url: `${API_URL}/api/${Endpoints.ROLES.GET_LIST}`,
          body,
        });
        if (response && (!response.id || !response.message)) {
          response = { message: "El Rol fue creado exitosamente" };
        }
      } else {
        response = await apiPut({
          url: `${API_URL}/api/${Endpoints.ROLES.GET_LIST}/${rolToEditOrView.id}`,
          body,
        });
        if (response && (!response.id || !response.message)) {
          response = { message: "El Rol fue modificado exitosamente" };
        }
      }
      const message = await prepareToastData(response, "Rol", "id", "El");
      handleClose(message);
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    }
  };

  const tabName = () => {
    if (createMode) {
      return "Nuevo Rol";
    }
    if (editionMode) {
      return "Editar Nombre del Rol";
    }
    return "Nombre del Rol";
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setErrorMessage(undefined);
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={true}
        bodyContent={errorMessage}
      />
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
          >
            <Grid item xs={12} md={4}>
              <span className={`modal-main-title fw-5`}>{tabName()}</span>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <LayoutGridBoxComponent>
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={5}>
                        <Button
                          className="login-button text-button"
                          style={{ width: 130 }}
                          variant="text"
                          color="info"
                          onClick={() => handleClose()}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          className="login-button ligth-green "
                          style={{ margin: "auto", width: 130 }}
                          variant="contained"
                          onClick={() => {
                            if (editionMode) {
                              onSubmit();
                            } else {
                              setEditionMode(true);
                            }
                          }}
                        >
                          {editionMode ? "Guardar" : "Editar"}
                        </Button>
                      </Grid>
                    </Grid>
                  </LayoutGridBoxComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
          >
            <Grid item xs={12}>
              {!loading && formValues && (
                <RolFormGridComponent
                  permissionsList={rolesData.permissionsList}
                  formValues={formValues}
                  onChange={onChange}
                  onSubmit={onSubmit}
                  editionMode={editionMode}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default NewRoleComponent;
