import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomLinkProps, ReduxType } from "../../../shared/types";
import {
  findDeviceNetworksAction,
  findDeviceStatesAction,
  findDeviceTypesAction,
  getDevicesListDataAction,
  getDeviceUnlinkListDataAction,
  linkOrUnlinkOrganizationAction,
} from "../../../store/devices/actions";
import { getOrganizationsListDataAction } from "../../../store/organizations/actions";
import { ToastComponent } from "../../toast";
import { GenericGrid } from "../genericGrid";
import { NewDeviceComponent } from "../newDevice";
import { defaultColumns, organizationDeviceModalColumns } from "./Columns";

const DeviceListComponent = (props: any) => {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [editionMode, setEditionMode] = useState<any>(false);
  const [createMode, setCreateMode] = useState<any>(false);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const [deviceToEditOrView, setDeviceToEditOrView] = useState();

  const { deviceData, organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  const {
    fromOrganization,
    organizationId,
    selectedFromOrgRows,
    setSelectedFromOrgRows,
  } = props;

  React.useEffect(() => {
    getDevicesList();
  }, []);

  React.useEffect(() => {
    if (fromOrganization) {
      getUnlinkDeviceList();
    }
  }, []);

  const getUnlinkDeviceList = async () => {
    await dispatch(getDeviceUnlinkListDataAction());
  };

  const getDevicesList = async () => {
    await dispatch(getDevicesListDataAction());
  };

  React.useEffect(() => {
    if (!organizationsData.organizationsList) {
      getOrganizationsList();
    }
  }, []);

  const getOrganizationsList = async () => {
    await dispatch(getOrganizationsListDataAction());
  };

  React.useEffect(() => {
    getAllDeviceData();
  }, []);

  const getAllDeviceData = async () => {
    await dispatch(findDeviceStatesAction());
    await dispatch(findDeviceTypesAction());
    await dispatch(findDeviceNetworksAction());
  };

  const linkOrUnlinkOrganization = async (link: any) => {
    if (!selectedRows || selectedRows.length === 0) return;
    const orgId =
      organizationsData &&
      organizationsData.organizationsList &&
      organizationsData.organizationsList.length > 0
        ? organizationsData.organizationsList[0].id
        : 1;
    const body = {
      organizacionId: link ? orgId : null,
      dispositivos: selectedRows,
    };
    await dispatch(linkOrUnlinkOrganizationAction(body));
    setTimeout(async () => {
      await getDevicesList();
    }, 500);
    setSelectedRows([]);
  };

  const action = async (action: "VIEW" | "DELETE" | "EDIT", row: any) => {
    switch (action) {
      case "EDIT":
        await setDeviceToEditOrView(row);
        setEditionMode(true);
        setOpen(!open);
        break;
      case "VIEW":
        await setDeviceToEditOrView(row);
        await setOpen(!open);
        break;
    }
  };

  const selectedRowsDisabled = !selectedRows || selectedRows.length === 0;

  const headerButtons: CustomLinkProps[] = fromOrganization
    ? []
    : [
        {
          id: "organizations",
          label: "ORGANIZACIONES",
          type: "SELECT",
          disabled: selectedRowsDisabled,
          items: [
            {
              value: 10,
              label: "Asignar organización",
              onClick: () => linkOrUnlinkOrganization(true),
            },
            {
              value: 5,
              label: "Remover organización",
              onClick: () => linkOrUnlinkOrganization(false),
            },
          ],
        },
        {
          id: "newDevice",
          label: "NUEVO DISPOSITIVO",
          type: "SIMPLE",
          className: "login-button ligth-green",
          variant: "contained",
          onClick: () => {
            setEditionMode(true);
            setCreateMode(true);
            setOpen(true);
          },
        },
      ];

  const closeAndResetData = async (device: any) => {
    if (device !== "cancel" && !device.cancelable) {
      setMessage(device);
      setShowToast(true);
    }
    setDeviceToEditOrView(undefined);
    setEditionMode(false);
    setCreateMode(false);
    setOpen(false);
    setTimeout(async () => {
      await getDevicesList();
    }, 500);
  };

  const cleanAndClose = async () => {
    await setShowToast(false);
    await setMessage(undefined);
    await setEditionMode(false);
    await setCreateMode(false);
  };

  const searchCallback = (val: any, value: any) => {
    return (
      val.id === value ||
      (val.nombre && val.nombre.toUpperCase().indexOf(value) > -1) ||
      (val.dispositivo_tipo &&
        val.dispositivo_tipo.nombre &&
        val.dispositivo_tipo.nombre.toUpperCase().indexOf(value) > -1) ||
      (val.identificador &&
        val.identificador.toUpperCase().indexOf(value) > -1) ||
      (val.version && val.version.toUpperCase().indexOf(value) > -1) ||
      (val.organizacion && val.organizacion.toUpperCase().indexOf(value) > -1)
    );
  };

  return (
    <>
      {open && (
        <NewDeviceComponent
          open={open}
          organizationId={organizationId}
          deviceToEditOrView={deviceToEditOrView}
          editionMode={editionMode}
          createMode={createMode}
          setEditionMode={setEditionMode}
          handleClose={(device: any) => closeAndResetData(device)}
        />
      )}
      <ToastComponent
        showToast={showToast}
        toggleShow={cleanAndClose}
        errorType={false}
        bodyContent={message}
      />

      <GenericGrid
        selectedRows={fromOrganization ? selectedFromOrgRows : selectedRows}
        setSelectedRows={
          fromOrganization ? setSelectedFromOrgRows : setSelectedRows
        }
        headerButtons={headerButtons}
        columns={
          fromOrganization ? organizationDeviceModalColumns : defaultColumns
        }
        displayData={
          fromOrganization ? deviceData.devicesUnlink : deviceData.devicesList
        }
        searchCallback={searchCallback}
        header={true}
        hasSearchInput={true}
        onActionClick={action}
        actions={{
          view: true,
          delete: false,
          edit: true,
        }}
      />
    </>
  );
};

export default DeviceListComponent;
