import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";

import { ToastProps } from "../../shared/types";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ToastComponent = (props: ToastProps) => {
  const { bodyContent, showToast, errorType, toggleShow } = props;
  return (
    <Snackbar
      open={showToast}
      onClose={toggleShow}
      message={bodyContent}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert severity={errorType ? "error" : "success"} sx={{ width: "100%" }}>
        {bodyContent}
      </Alert>
    </Snackbar>
  );
};

export default ToastComponent;
