import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  paperClasses,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import {
  alphanumericRegex,
  dniRegex,
  emailRegex,
} from "../../../../shared/regexPatterns";
import { CustomTextField } from "../../../formInputs";
import { StyledSelect } from "../../../formInputs/SelectComponent/StyledSelect";
import { GridActions } from "../../gridActions";

export interface FormGridProps {
  rolesList: any[];
  organizations: any[];
  createMode: boolean;
  editionMode?: boolean;
  onChange: ({ value, name }: { value: any; name: string }) => void;
  handlePermissionDelete: (id: number) => Promise<void>;
  formValues: any;
  organizationId?: any;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${paperClasses.rounded}`]: {
    boxShadow: "none",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    lineHeight: "unset",
  },
}));

const columns: any = [
  { id: "name", label: "Permisos", minWidth: 500 },
  { id: "acciones", label: "Acciones", minWidth: 50, align: "center" },
];

const FormGridComponent = (props: FormGridProps) => {
  const {
    onChange,
    handlePermissionDelete,
    formValues,
    editionMode,
    rolesList,
    organizations,
    organizationId,
    createMode,
  } = props;

  return (
    <form>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="provincia-select-label">Organización *</InputLabel>
            <StyledSelect
              labelId="provincia-select-label"
              id="organizacionId"
              name="organizacionId"
              defaultValue={
                formValues.organizacionId !== null
                  ? formValues.organizacionId
                  : ""
              }
              onChange={(e) =>
                onChange({ value: e.target.value, name: "organizacionId" })
              }
              disabled={!editionMode || !!organizationId}
            >
              {organizations &&
                organizations.map((o, index: number) => {
                  return (
                    <MenuItem key={`index-${index}`} value={o.id}>
                      {o.nombre}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            id="fullname"
            name="fullname"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.fullname}
            onChange={onChange}
            required
            label="Nombre y Apellido"
            pattern={alphanumericRegex}
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            id="email"
            name="email"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.email}
            onChange={onChange}
            pattern={emailRegex}
            label="Email"
            required
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomTextField
            id="dni"
            name="dni"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.dni}
            pattern={dniRegex}
            onChange={onChange}
            label="DNI"
            disabled={!editionMode}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <CustomTextField
            id="phone"
            name="phone"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.phone}
            onChange={onChange}
            pattern={alphanumericRegex}
            label="Teléfono"
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="tipo-select-label">Rol *</InputLabel>
            <StyledSelect
              labelId="tipo-select-label"
              id="rolId"
              name="rolId"
              defaultValue={formValues.rolId !== null ? formValues.rolId : ""}
              required
              onChange={(e) =>
                onChange({ value: e.target.value, name: "rolId" })
              }
              disabled={!editionMode}
            >
              {rolesList &&
                rolesList.map((t, index: number) => {
                  return (
                    <MenuItem key={`index-${index}`} value={t.id}>
                      {t.nombre}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.active}
                disabled={!editionMode}
                onChange={(e) =>
                  onChange({ value: !formValues.active, name: "active" })
                }
              />
            }
            label="Activo"
            labelPlacement="start"
          />
        </Grid>
        {!createMode && (
          <Grid item xs={12}>
            <StyledPaper
              className="mt-2"
              sx={{ width: "100%", overflow: "hidden" }}
            >
              <TableContainer style={{ maxHeight: 532 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns &&
                        columns.map((column: any) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formValues.usuario_permisos &&
                      formValues.usuario_permisos.length > 0 &&
                      formValues.usuario_permisos
                        .filter((val: any) =>
                          !!val.deshabilitado ? false : true
                        )
                        .map((row: any) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columns &&
                                columns.map((column: any) => {
                                  const value = !row[column.id]
                                    ? "-"
                                    : row[column.id];
                                  if (column.render) {
                                    return (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                      >
                                        {column.render(value, row)}
                                      </TableCell>
                                    );
                                  }
                                  switch (column.id) {
                                    case "acciones":
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          <GridActions
                                            data={value}
                                            align={column.align}
                                            actions={
                                              !editionMode
                                                ? {
                                                    view: false,
                                                    delete: false,
                                                    edit: false,
                                                  }
                                                : {
                                                    view: false,
                                                    delete: true,
                                                    edit: false,
                                                  }
                                            }
                                            onClick={() =>
                                              handlePermissionDelete(row.id)
                                            }
                                          />
                                        </TableCell>
                                      );
                                    default:
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          {row.permiso.nombre}
                                        </TableCell>
                                      );
                                  }
                                })}
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledPaper>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default FormGridComponent;
