import { Endpoints } from "../../config/endpoints";
import {
  API_URL,
  API_URL_GET_TO_ID,
  LOCAL_STORAGE_JWT,
  LOCAL_STORAGE_PROFILE,
} from "../../config/general-config";
import { apiPost } from "../../shared/ApiService";
import { asyncLocalStorage } from "../../shared/helper";
import {
  ApiRequest,
  ApiResponse,
  ApiResponseGeoToID,
} from "../../shared/types";

import { AuthenticateReponse } from "./Type";

const loginWithUsernameAndPassword = async (
  username: string,
  password: string
) => {
  const responseLogin: ApiResponseGeoToID = await genericGeoToIdRequest(
    Endpoints.LOGIN,
    { email: username, pass: password }
  );

  const access_token = responseLogin.access_token;

  await saveUserProfileToLocalStorage({ username: username });
  await saveTokenToLocalStorage(access_token);

  return {
    username,
    access_token,
  };
};

const getProfileUser = async () => {
  const response: ApiResponse = await genericRequest(
    "v1/users",
    Endpoints.PROFILE,
    {},
    7,
    getToken()
  );
  await saveUserProfileToLocalStorage(response.data);
  return response.data;
};

const genericGeoToIdRequest = async (
  method: string,
  body: any,
  token?: string
) => {
  if (token) {
    body.token = token;
  }
  const response = await apiPost({
    url: `${API_URL_GET_TO_ID}/${method}`,
    body: body,
    unauthorizedCallback: () => {
      unauthorizedCallback();
    },
  });

  if (response.code === 403 || response.message) {
    throw new Error(response.message);
  }

  return response;
};

const genericRequest = async (
  mod: string,
  fun: string,
  args: any,
  sequence: number,
  token?: string
) => {
  const bodyProfile: ApiRequest = {
    mod: mod,
    fun: fun,
    args: args,
    config: {},
    sequence: sequence,
  };

  if (token) {
    bodyProfile.token = token;
  }

  const response = await apiPost({
    url: API_URL,
    body: bodyProfile,
    unauthorizedCallback: () => {
      unauthorizedCallback();
    },
  });

  if (response.code === 5001 || response.error) {
    throw new Error(response.message);
  }

  return response;
};

const logout = () => {
  localStorage.removeItem(LOCAL_STORAGE_JWT);
  localStorage.removeItem(LOCAL_STORAGE_PROFILE);
};

const getCurrentUser = () => {
  const jsonProfile = localStorage.getItem(LOCAL_STORAGE_PROFILE);
  if (!jsonProfile) {
    return undefined;
  }
  const user: AuthenticateReponse = JSON.parse(
    jsonProfile
  ) as AuthenticateReponse;
  return user.fullname;
};

const getCurrentProfileUser = () => {
  return localStorage.getItem(LOCAL_STORAGE_PROFILE) || undefined;
};

const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_JWT) || undefined;
};

const isLoggedIn = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_JWT);
  const user = localStorage.getItem(LOCAL_STORAGE_PROFILE);

  return !!token && !!user;
};

const saveUserProfileToLocalStorage = async (profile: any) => {
  await asyncLocalStorage.setItem(
    LOCAL_STORAGE_PROFILE,
    JSON.stringify(profile)
  );
};

const saveTokenToLocalStorage = async (token: string) => {
  await asyncLocalStorage.setItem(LOCAL_STORAGE_JWT, token);
};

const unauthorizedCallback = function () {
  logout();
  window.location.reload();
};

export {
  getToken,
  getCurrentUser,
  getCurrentProfileUser,
  saveUserProfileToLocalStorage,
  isLoggedIn,
  logout,
  getProfileUser,
  loginWithUsernameAndPassword,
  unauthorizedCallback,
};
