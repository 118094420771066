import { Button, Divider, Grid, Modal } from "@mui/material";

import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Endpoints } from "../../../config/endpoints";
import { API_URL } from "../../../config/general-config";
import { apiPost, apiPut } from "../../../shared/ApiService";
import { newDeviceFormValues } from "../../../shared/FormValues";
import { ReduxType } from "../../../shared/types";
import { validateForm } from "../../../shared/validations";
import { ToastComponent } from "../../toast";
import { LayoutGridBoxComponent } from "../layoutGridBox";
import { validatorValues } from "./constants/Constant";
import { DeviceFormGridComponent } from "./deviceFormGrid";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -60%)",
  width: 800,
  backgroundColor: "#FFFFFF",
};

const NewDeviceComponent = (props: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<any>(newDeviceFormValues);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { deviceData, organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  const {
    createMode,
    deviceToEditOrView,
    editionMode,
    handleClose,
    open,
    setEditionMode,
  } = props;

  useEffect(() => {
    if (!createMode && deviceToEditOrView) {
      initialUpdate(deviceToEditOrView, false);
    }
    if (createMode && editionMode) {
      initialUpdate(newDeviceFormValues, false);
    }
  }, [createMode, editionMode, deviceToEditOrView]);

  const onChange = ({ value, name }: any) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const initialUpdate = async (deviceToEditOrView: any, loading: boolean) => {
    await setFormValues({
      organizacionId: deviceToEditOrView.organizacionId,
      tipoId: deviceToEditOrView.tipoId,
      tipoRedId: deviceToEditOrView.tipoRedId,
      numero: deviceToEditOrView.numero,
      identificador: deviceToEditOrView.identificador,
      version: deviceToEditOrView.version,
      nombre: deviceToEditOrView.nombre,
      estadoId: deviceToEditOrView.estadoId,
      orden: deviceToEditOrView.orden,
    });
    await setLoading(loading);
  };

  const prepareToastData = async (response: any, close = true) => {
    let message;
    if (response && response.id) {
      message = `El Dispositivo #${response.id} fue creado de forma correcta.`;
    } else if (response.message) {
      message = response.message;
    } else {
      await setShowToast(true);
      await setErrorMessage(response);
    }
    if (close && message) {
      handleClose(message);
    }
  };

  const onSubmit = async () => {
    try {
      if (!validateForm(formValues, validatorValues)) {
        throw new Error(
          "Complete todos los datos obligatorios antes de continuar."
        );
      }
      formValues.organizacionId =
        formValues.organizacionId === "" ? null : formValues.organizacionId;
      formValues.estadoId = formValues.estadoId ? 1 : 2;
      let response;
      if (createMode) {
        response = await apiPost({
          url: `${API_URL}/${Endpoints.DEVICES.CREATE}`,
          body: formValues,
        });
      } else {
        response = await apiPut({
          url: `${API_URL}/${Endpoints.DEVICES.UPDATE}/${deviceToEditOrView.id}`,
          body: formValues,
        });
      }
      prepareToastData(response);
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    }
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setErrorMessage(undefined);
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={true}
        bodyContent={errorMessage}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
          >
            <Grid item xs={12} md={4}>
              <span className={`modal-main-title fw-5`}>
                {createMode
                  ? `Nuevo dispositivo`
                  : `${editionMode ? `Editar ` : ``}${
                      formValues && formValues.nombre
                    }`}
              </span>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <LayoutGridBoxComponent>
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={5}>
                        <Button
                          className="login-button text-button"
                          style={{ width: 130 }}
                          variant="text"
                          color="info"
                          onClick={handleClose}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          className="login-button ligth-green "
                          style={{ margin: "auto", width: 130 }}
                          variant="contained"
                          onClick={() => {
                            if (editionMode) {
                              onSubmit();
                            } else {
                              setEditionMode(true);
                            }
                          }}
                        >
                          {editionMode ? "Guardar" : "Editar"}
                        </Button>
                      </Grid>
                    </Grid>
                  </LayoutGridBoxComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
          >
            <Grid item xs={12}>
              {!loading &&
                formValues &&
                deviceData.states &&
                deviceData.networks &&
                deviceData.types && (
                  <DeviceFormGridComponent
                    organizations={organizationsData.organizationsList}
                    states={deviceData.states}
                    networks={deviceData.networks}
                    types={deviceData.types}
                    formValues={formValues}
                    onChange={onChange}
                    editionMode={editionMode}
                  />
                )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default NewDeviceComponent;
