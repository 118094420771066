import {
  FormControl,
  Grid,
  styled,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
} from "@mui/material";
import React, { CSSProperties } from "react";
import { CustomButton } from "../../../customButton";
import { CustomTextField } from "../../../formInputs";
import SuccessIcon from "../../../../assets/icons/svg/success.svg";

export interface FormGridProps {
  permissionsList: any[];
  editionMode?: boolean;
  onSubmit: () => void;
  onChange: ({ value, name }: { value: any; name: string }) => void;
  formValues: any;
}

const css: { [key: string]: CSSProperties } = {
  [`&.${toggleButtonClasses.selected}`]: {
    background: "rgba(139, 195, 74, 0.2)",
    borderColor: "#6BAA00 !important",
  },
  [`&.${toggleButtonClasses.standard}`]: {
    borderColor: "rgb(189, 189, 189) !important",
  },
};

const StyledToggleButton = styled(ToggleButton)(() => css);

const FormGridComponent = (props: FormGridProps) => {
  const { onSubmit, onChange, formValues, editionMode, permissionsList } =
    props;

  return (
    <form onSubmit={onSubmit}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={12}>
          <CustomTextField
            id="nombre"
            name="nombre"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.nombre}
            onChange={onChange}
            label="Nombre"
            required
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <h5 className="w-500">
                Permisos asignados
                {` ${formValues.rol_permisos.length}` || ""}
              </h5>
            </Grid>
            <Grid item xs={6}>
              <CustomButton
                style={{ float: "right" }}
                label="Asignar todos"
                id="asignar-button"
                type="SIMPLE"
                disabled={!editionMode}
                onClick={() => {
                  if (!editionMode) return;
                  const values = [...permissionsList.map((val) => val.id)];
                  onChange({
                    value:
                      formValues.rol_permisos.length === values.length
                        ? []
                        : values,
                    name: "rol_permisos",
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth style={{ height: "100%" }}>
            <ToggleButtonGroup
              orientation="horizontal"
              value={formValues.rol_permisos}
              style={{
                rowGap: 5,
                columnGap: 15,
                overflow: "auto",
                maxHeight: 200,
                flexWrap: "wrap",
              }}
              disabled={!editionMode}
              onChange={(val, newFormat) => {
                onChange({ value: newFormat, name: "rol_permisos" });
              }}
            >
              {permissionsList &&
                permissionsList.length > 0 &&
                permissionsList.map((value) => {
                  return (
                    <StyledToggleButton
                      style={{
                        width: "22%",
                        gap: 3,
                        border: "1px solid",
                        borderRadius: 50,
                        margin: 5,
                        color: "#222222",
                      }}
                      value={value.id}
                      key={value.id}
                    >
                      {formValues.rol_permisos.findIndex(
                        (val: number) => val === value.id
                      ) !== -1 && (
                        <img src={SuccessIcon} alt="success-message" />
                      )}
                      {value.nombre}
                    </StyledToggleButton>
                  );
                })}
            </ToggleButtonGroup>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormGridComponent;
