import { Grid } from "@mui/material";
import React from "react";

import { useSelector } from "react-redux";
import { UserListComponent } from "../../components/dashboard";

import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

import { ReduxType } from "../../shared/types";

const UserPage = () => {
  const { dashboardData, userData } = useSelector((state: ReduxType) => {
    return state;
  });

  const verifyLoading = () => {
    return userData.loading || dashboardData.loading;
  };

  return (
    <AuthenticatedRoute withHeader path={Paths.USERS} loading={verifyLoading()}>
      <div style={{ padding: "1rem" }}>
        <Grid>
          <UserListComponent />
        </Grid>
      </div>
    </AuthenticatedRoute>
  );
};

export default UserPage;
