import { CSSProperties } from "react";

export interface BaseApiParams {
  url: string;
  unauthorizedCallback?: any;
  showSuccesMessage?: boolean;
}

export interface GetParams extends BaseApiParams {}

export interface DeleteParams extends BaseApiParams {}

export interface PostParams extends BaseApiParams {
  body: any;
}

export interface PutParams extends BaseApiParams {
  body: any;
}

export interface PostDownloadParams extends PostParams {
  fileName: string;
  extension: string;
}

export enum ConfirmDialogTypeEnum {
  Info = "Info",
  Exito = "Exito",
  Advertencia = "Advertencia",
  Eliminar = "Eliminar",
  Error = "Error",
}

export enum ScreensEnum {
  ORGANIZACIONES = "ORGANIZATION",
  NUEVA_ORGANIZACION = "NEW_ORGANIZATION",
  DISPOSITIVOS = "DEVICES",
  NUEVO_DISPOSITIVO = "NEW_DEVICE",
  USUARIOS = "USERS",
  COSECHAS = "COSECHAS",
  ROLES = "ROLES",
  REPORTE = "REPORTS",
}

export enum ScreenPermissions {
  ORGANIZATION = "Organizaciones",
  COMPANY = "Compañías",
  ESTABLISHMENT = "Establecimientos",
  DEVICES = "Dispositivos",
  ROL = "Roles",
  USER = "Usuarios",
  COSECHAS = "Cosechas",
  USER_PLATFORM = "Usuarios plataforma",
  SILO = "Silos",
}

export type AuthenticatedRouteProps = {
  path: string;
  children: any;
  login?: boolean;
  withHeader?: boolean;
  dashboard?: boolean;
  withLogo?: boolean;
  loading?: boolean;
  withDashboardLogo?: boolean;
  headerNavigationName?: string;
  goBack?: () => void;
  withPadding?: boolean;
  handleScreenChange?: any;
  currentScreen?: ScreensEnum;
  setCurentScreen?: any;
  mainTitle?: string;
};

export interface ReduxBase {
  loading: boolean;
  error: {
    message: string;
  };
}

export interface ReduxUserData extends ReduxBase {
  user: any;
  usersList: any[];
  userProfile: any;
  userReset: any;
  userCreateOrUpdateResponse: any;
}

export interface ReduxDashboardData extends ReduxBase {
  currentDashboardOrg: DashboardStatsProps;
}

export interface ReduxOrganizationsData extends ReduxBase {
  cities: any[];
  provinces: any[];
  localities: any[];
  companiesList: any[];
  usersList: any[];
  establishmentsList: any[];
  devicesList: any[];
  organizationsList: any[];
  noOrganizationCompaniesList: any[];
  createOrUpdateResponse: any;
}

export interface ReduxRolesData extends ReduxBase {
  rolesList: any[];
  permissionsList: any[];
}

export interface ReduxDeviceData extends ReduxBase {
  devicesList: any[];
  device: any;
  devicesUnlink: any[];
  states: any[];
  types: any[];
  networks: any[];
}

export interface ReduxHarvestsData extends ReduxBase {
  harvestsList: any[];
}

export type ReduxType = {
  dashboardData: ReduxDashboardData;
  userData: ReduxUserData;
  organizationsData: ReduxOrganizationsData;
  rolesData: ReduxRolesData;
  deviceData: ReduxDeviceData;
  harvestsData: ReduxHarvestsData;
};

export interface NavBarProps {
  path: string;
}

export interface HeaderProps {
  width: number;
  withLogo?: boolean;
  withDashboardLogo?: boolean;
  dashboard?: boolean;
  login?: boolean;
  collapsed: boolean;
  headerNavigationName?: string;
  toggleCollapsed: () => void;
  goBack?: () => void;
}

export interface ModalProps {
  open: boolean;
  formValues?: any;
  name?: "company" | "device" | "establishment" | "user";
  onSubmit?: (...args: any) => Promise<void>;
  title?: string;
  submitName?: string;
}

export interface UserLoginRequest {
  username: string | undefined;
  password: string | undefined;
}

export interface ToastProps {
  bodyContent: any;
  showToast: boolean;
  errorType: boolean;
  toggleShow: () => void;
}

export interface ApiRequest {
  mod: string;
  fun: string;
  args: any;
  config: any;
  sequence: number;
  token?: string;
}

export interface ApiResponseGeoToID {
  access_token: string;
  expires_in: number;
  token_type: string;
}

export interface ApiResponse {
  error: boolean;
  code: number;
  message: string;
  data: any;
  info: {
    time: number;
    sequence: number;
  };
}

export interface OrganizationProps {
  user_id: number;
  organization_id: number;
  organization_name: string;
}

export interface DashboardStatsProps {
  establishment_data: DashboardEstablishmentStatsProps[];
  device_data: DashboardDeviceStatsProps[];
  silo_data: DashboardSiloStatsProps[];
  siloCount: number;
  tonsCount: number;
  alertsCount: number;
  monitored_tons_crop_data: MonitoredTonsCrop[];
  monitored_tons_establishment_data: MonitoredTonsEstablishment[];
}

export interface DashboardEstablishmentStatsProps {
  LOTE_NOMBRE: string;
  CANTIDAD: number;
  PRIMER_SILO_LATITUD: string;
  PRIMER_SILO_LONGITUD: string;
}

export interface DashboardDeviceStatsProps {
  DISPOSITIVO_TIPO: string;
  CANTIDAD: number;
}

export interface DashboardSiloStatsProps {
  ELEMENTO_CANTIDAD_DISPOSITIVO: number;
  ELEMENTO_NOMBRE: string;
  ELEMENTO_PRODUCTO: string;
  CAMPO_NOMBRE: string;
  LOTE_NOMBRE: string;
  ALERTA_COLOR: string;
  ALERTA_NUMERO: number;
  Indicador: string;
  Valor_Alerta: number;
  PESO: number;
}

export interface CurrentEstablishment {
  ORGANIZACION_ID: number;
  LOTE_ID: number;
  CAMPO_NOMBRE: string;
  LOTE_NOMBRE: string;
  LOTE_PERIMETRO: any;
  ELEMENTO_ID: number;
  ELEMENTO_NOMBRE: string;
  ELEMENTO_PRODUCTO: string;
  ELEMENTO_COSECHA: string;
  ELEMENTO_TIPO: string;
  ELEMENTO_CANTIDAD_DISPOSITIVO: number;
  ELEMENTO_LATITUD: string;
  ELEMENTO_LONGITUD: string;
  PESO: any;
}

export interface InfoWindow {
  SILO_ID: number;
  DISPOSITIVO_ID: number;
  DISPOSITIVO_NOMBRE: string;
  DISPOSITIVO_TIPO: string;
  DISPOSITIVO_ESTADO: string;
  "MAX(FECHA)": Date;
  ID_DISPOSITIVO: number;
  ALERTA_COLOR: string;
  ALERTA_NUMERO: number;
}

export interface InfoWindowTooltipProps {
  marker: CurrentEstablishment;
  mapInfo?: InfoWindow;
}

export interface MonitoredTonsCrop {
  ELEMENTO_PRODUCTO: string;
  PESO: number;
}

export interface MonitoredTonsEstablishment {
  CAMPO_NOMBRE: string;
  PESO: number;
}

export interface Alert {
  ORGANIZACION: number;
  ID_ALERTA: number;
  ALERTA_COLOR: string;
  ALERTA_NUMERO: number;
  FECHA: Date;
  TipoAlerta: string;
  Establecimiento: string;
  Lote: string;
  ID_SILO: number;
  Silobolsa: string;
  Valor_Alerta: string;
  DISPOSITIVO: string;
  Indicador: string;
}

export interface CustomLinkProps {
  label: string;
  type: "SIMPLE" | "SELECT";
  id: string | number;
  disabled?: boolean;
  title?: string;
  variant?: "text" | "outlined" | "contained";
  startAdornment?: boolean;
  style?: CSSProperties;
  className?: string;
  select?: boolean;
  items?: any[];
  onClick?: (...args: any) => void;
}

export interface ArrowButtonProps {
  onClick: () => void;
  disabled: boolean;
  className?: string;
}

export interface FormGridProps {
  formValues: any;
  onChange: ({ value, name }: { value: any; name: string }) => void;
  createMode?: boolean;
  editionMode?: boolean;
  linkMode?: boolean;
  viewMode?: boolean;
  onSubmit?: (...args: any) => Promise<void>;
  organizationId?: number;
  selectedFromOrgRows?: any[];
  setSelectedFromOrgRows?: any;
}

export interface ModalFormHandlerProps extends FormGridProps {
  name?: "company" | "device" | "establishment" | "user";
}

export interface GridColumns {
  id: string;
  width?: number | string;
  render?: (value: any, record: any) => JSX.Element;
  label?: string;
}

export interface DeviceColumnProps {
  id:
    | "checkbox"
    | "id"
    | "nombre"
    | "tipo"
    | "numero"
    | "identificador"
    | "version"
    | "organizacion"
    | "estado"
    | "network_server"
    | "createdAt"
    | "acciones";
  label: string;
  align?: "center" | "right";
  width?: string | number;
  idValue?: string;
  applyFormat?: boolean;
  render?: (data: any, record: any) => JSX.Element;
  format?: any;
}
