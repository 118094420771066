import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Endpoints } from "../../../config/endpoints";
import { API_URL } from "../../../config/general-config";
import { apiPost, apiPut } from "../../../shared/ApiService";
import { ReduxType } from "../../../shared/types";
import { getHarvestsListDataAction } from "../../../store/harvests/actions";
import { CustomTextField } from "../../formInputs";
import { ToastComponent } from "../../toast";
import { GenericGrid } from "../genericGrid";

import { Columns } from "./Columns";

export const HarvestsListComponent = () => {
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [successMessage, setSuccessMessage] = useState<any>();
  const [editMode, setEditionMode] = useState(false);
  const [harvestInput, setHarvestInput] = useState({ value: "", id: 0 });

  const { harvestsData } = useSelector((state: ReduxType) => {
    return state;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (harvestsData.harvestsList.length === 0) {
      getHarvestsList();
    }
  }, []);

  const getHarvestsList = async () => {
    await dispatch(getHarvestsListDataAction());
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    if (errorMessage) {
      await setErrorMessage(undefined);
    }
    if (successMessage) {
      await setSuccessMessage(undefined);
    }
  };

  const onClickEditHarvest = async (
    action: "VIEW" | "DELETE" | "EDIT",
    record: any
  ) => {
    setHarvestInput({ value: record.nombre, id: record.id });
    setEditionMode(true);
  };

  const onInputChange = (e: any) => {
    setHarvestInput({ ...harvestInput, value: e.value });
  };

  const onHarvestSubmit = async () => {
    if (!!!harvestInput.value) return;
    try {
      const body = { nombre: harvestInput.value };
      let response;
      if (!editMode) {
        response = await apiPost({
          url: `${API_URL}/api/${Endpoints.HARVESTS}`,
          body,
        });
      } else {
        response = await apiPut({
          url: `${API_URL}/api/${Endpoints.HARVESTS}/${harvestInput.id}`,
          body,
        });
      }
      if (response) {
        await setShowToast(true);
        typeof response === "string"
          ? await setErrorMessage(response)
          : await setSuccessMessage(`Cosecha guardad correctamente.`);
        setHarvestInput({ value: "", id: 0 });
        setEditionMode(false);
        setTimeout(async () => {
          await getHarvestsList();
        }, 500);
      }
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    }
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={errorMessage ? true : false}
        bodyContent={errorMessage || successMessage}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <GenericGrid
                columns={Columns}
                displayData={harvestsData.harvestsList}
                onActionClick={onClickEditHarvest}
                actions={{
                  view: false,
                  delete: false,
                  edit: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Grid
                container
                className="white"
                alignItems="center"
                justifyContent="space-between"
                rowGap={2}
                style={{ padding: "1rem" }}
              >
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={8}>
                      <h3
                        className="w-500"
                        style={{ wordBreak: "break-all", margin: 0 }}
                      >
                        {!editMode ? "Nueva cosecha" : "Editar cosecha"}
                      </h3>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    id="cosecha"
                    name="cosecha"
                    label="Nombre"
                    variant="filled"
                    onChange={onInputChange}
                    defaultValue={harvestInput.value}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className="login-button ligth-green "
                    style={{ width: "auto" }}
                    variant="contained"
                    onClick={onHarvestSubmit}
                    type="button"
                  >
                    {!editMode ? "Crear" : "Guardar"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
