import { Button, Grid } from "@mui/material";
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { useSelector } from "react-redux";

import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

import { ReduxType } from "../../shared/types";
import { useNavigate } from "react-router-dom";

const NoPermissionsPage = () => {
  const { dashboardData, userData } = useSelector((state: ReduxType) => {
    return state;
  });

  const verifyLoading = () => {
    return userData.loading || dashboardData.loading;
  };

  const navigate = useNavigate();

  return (
    <AuthenticatedRoute
      withHeader
      path={Paths.ORGANIZATION}
      loading={verifyLoading()}
    >
      <Grid style={{ padding: "1rem", height: "100%" }}>
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            flexDirection: "column",
          }}
        >
          <ErrorOutlineIcon
            style={{ width: 64, height: 64 }}
            className="color-green"
          />
          <h2 style={{ textAlign: "center" }}>
            NO TIENES PERMISOS NECESARIOS PARA ACCEDER A LA RUTA INDICADA.
          </h2>
          <Button
            onClick={() => navigate(Paths.ROOT)}
            className=" ligth-green"
            variant="contained"
            style={{ marginTop: 7 }}
          >
            VOLVER A INICIO
          </Button>
        </div>
      </Grid>
    </AuthenticatedRoute>
  );
};

export default NoPermissionsPage;
