import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { ReduxType } from "../../../shared/types";
import { updateUserAction } from "../../../store/users/actions";
import {
  getPermissionsListAction,
  getRolesListAction,
} from "../../../store/roles/actions";
import { LayoutGridBoxComponent } from "../layoutGridBox";
import { UserFormGridComponent } from "./userFormGrid";
import { ToastComponent } from "../../toast";

import { Button, Divider, Grid, Modal } from "@mui/material";
import { apiDelete, apiPost, apiPut } from "../../../shared/ApiService";
import { API_URL } from "../../../config/general-config";
import { Endpoints } from "../../../config/endpoints";
import {
  alphanumericRegex,
  dniRegex,
  emailRegex,
  numericRegex,
} from "../../../shared/regexPatterns";
import { newUserFormValues } from "../../../shared/FormValues";
import { validateForm } from "../../../shared/validations";
import { getOrganizationsListDataAction } from "../../../store/organizations/actions";

const style = {
  position: "absolute" as "absolute",
  top: "58%",
  left: "50%",
  transform: "translate(-50%, -60%)",
  width: 800,
  backgroundColor: "#FFFFFF",
  maxHeight: "80%",
  display: "flex",
  flexDirection: "column",
};

const validatorValues = [
  { name: "organizacionId", nameToShow: "organizacion", required: true },
  {
    name: "fullname",
    nameToShow: "nombre y apellido",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "phone",
    nameToShow: "teléfono",
    pattern: numericRegex,
    required: false,
    regexMessage: "solo acepta valores numericos",
  },
  {
    name: "email",
    pattern: emailRegex,
    required: true,
    regexMessage: "solo acepta formato de email. Ej: example@gmail.com",
  },
  { name: "rolId", nameToShow: "rol", required: true },
  {
    name: "dni",
    pattern: dniRegex,
    required: true,
    regexMessage:
      "solo acepta formato de dni sin espacios ni puntos. Ej: 27845745",
  },
  { name: "active", required: false },
];

const NewUserComponent = (props: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<any>();
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const dispatch = useDispatch();

  const { organizationsData, rolesData } = useSelector((state: ReduxType) => {
    return state;
  });

  const {
    createMode,
    userToEditOrView,
    editionMode,
    handleClose,
    open,
    setEditionMode,
  } = props;

  useEffect(() => {
    if (!createMode && userToEditOrView) {
      initialUpdate(userToEditOrView, false);
    }
    if (createMode && editionMode) {
      initialUpdate(newUserFormValues, false);
    }
  }, [createMode, editionMode, userToEditOrView]);

  useEffect(() => {
    if (!rolesData.rolesList) {
      getAllRoleData();
    }
    if (!rolesData.permissionsList) {
      getPermissions();
    }
    if (!organizationsData.organizationsList) {
      getAllOrganizations();
    }
  }, []);

  const prepareToastData = async (response: any, close = true) => {
    let message;
    if (response && response.createUser && response.createUser.id) {
      message = `El Usuario con dni ${response.createUser.dni} fue creado de forma correcta.`;
    } else if (response && response.user) {
      message = `El Usuario con dni ${response.user.dni} fue modificado de forma correcta.`;
    } else if (response.message) {
      message = response.message;
    } else {
      await setShowToast(true);
      await setErrorMessage(response);
    }
    if (close && message) {
      handleClose(message);
    }
  };

  const getPermissions = async () => {
    await dispatch(getPermissionsListAction());
  };

  const getAllRoleData = async () => {
    await dispatch(getRolesListAction());
  };

  const getAllOrganizations = async () => {
    await dispatch(getOrganizationsListDataAction());
  };

  const onChange = ({ value, name }: any) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const initialUpdate = async (userToEditOrView: any, loading: boolean) => {
    await setFormValues({
      organizacionId: userToEditOrView.organizacionId,
      fullname: userToEditOrView.fullname,
      phone: userToEditOrView.phone,
      email: userToEditOrView.email,
      active: userToEditOrView.active,
      rolId: userToEditOrView.rolId,
      dni: userToEditOrView.dni,
      usuario_permisos: userToEditOrView.usuario_permisos,
    });
    await setLoading(loading);
  };

  const onSubmit = async (e: any) => {
    try {
      if (!validateForm(formValues, validatorValues)) {
        throw new Error(
          "Complete todos los datos obligatorios antes de continuar."
        );
      }
      formValues.username = formValues.email;
      formValues.organizacionId =
        formValues.organizacionId === "" ? null : formValues.organizacionId;
      formValues.rolId = formValues.rolId === "" ? null : formValues.rolId;

      let response;
      if (createMode) {
        response = await apiPost({
          url: `${API_URL}/${Endpoints.USERS.CREATE}`,
          body: formValues,
        });
      } else {
        response = await apiPut({
          url: `${API_URL}/${Endpoints.USERS.UPDATE}/${userToEditOrView.id}`,
          body: formValues,
        });
      }
      prepareToastData(response);
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    }
  };

  const handlePermissionDelete = async (id: number) => {
    try {
      if (!id) return;
      setFormValues({
        ...formValues,
        usuario_permisos: formValues.usuario_permisos.map((val: any) =>
          val.id === id ? { ...val } : val
        ),
      });
      const request = await apiPut({
        body: {
          deshabilitado: 1,
        },
        url: `${API_URL}/${Endpoints.ROLES.PUT_PERMISSION}/${id}`,
      });
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    }
  };

  const tabName = () => {
    if (createMode) {
      return "Nuevo usuario";
    }
    return userToEditOrView && userToEditOrView.fullname;
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setErrorMessage(undefined);
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={true}
        bodyContent={errorMessage}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
          >
            <Grid item xs={12} md={4}>
              <span className={`modal-main-title fw-5`}>{tabName()}</span>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <LayoutGridBoxComponent>
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={5}>
                        <Button
                          className="login-button text-button"
                          style={{ width: 130 }}
                          variant="text"
                          color="info"
                          onClick={() => handleClose("cancel")}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          className="login-button ligth-green "
                          style={{ margin: "auto", width: 130 }}
                          variant="contained"
                          onClick={(event) => {
                            if (editionMode) {
                              onSubmit(event);
                            } else {
                              setEditionMode(true);
                            }
                          }}
                        >
                          {editionMode ? "Guardar" : "Editar"}
                        </Button>
                      </Grid>
                    </Grid>
                  </LayoutGridBoxComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
            style={{ overflowY: "auto" }}
          >
            <Grid item xs={12}>
              {!loading && formValues && (
                <UserFormGridComponent
                  organizations={organizationsData.organizationsList}
                  rolesList={rolesData.rolesList}
                  handlePermissionDelete={handlePermissionDelete}
                  formValues={formValues}
                  createMode={createMode}
                  onChange={onChange}
                  editionMode={editionMode}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default NewUserComponent;
