import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  alphanumericRegex,
  cuitRegex,
  emailRegex,
  numericRegex,
} from "../../../../shared/regexPatterns";
import { ReduxType } from "../../../../shared/types";
import {
  getCitiesListDataAction,
  getLocalitiesListDataAction,
  getProvincesListDataAction,
} from "../../../../store/organizations/actions";
import { CustomTextField } from "../../../formInputs";
import { StyledSelect } from "../../../formInputs/SelectComponent/StyledSelect";

export interface CompanyFormGridProps {
  onChange: ({ value, name }: { value: any; name: string }) => void;
  editionMode: boolean;
  formValues: any;
}

const CompanyFormGridComponent = (props: CompanyFormGridProps) => {
  const { onChange, formValues, editionMode } = props;
  const dispatch = useDispatch();

  const { organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  React.useEffect(() => {
    getProvince();
  }, []);

  const getProvince = async () => {
    await dispatch(getProvincesListDataAction(formValues ? false : true));
    formValues &&
      formValues.ciudadId &&
      (await dispatch(getLocalitiesListDataAction(formValues.ciudadId)));
  };

  const getOnChange = async (name: string, id: any) => {
    switch (name) {
      case "provinciaId":
        await dispatch(getCitiesListDataAction(id, true));
        onChange && onChange({ name: "provinciaId", value: id });
        break;
      case "ciudadId":
        await dispatch(getLocalitiesListDataAction(id));
        onChange && onChange({ name: "ciudadId", value: id });
        break;
      case "localidadId":
        onChange && onChange({ name: "localidadId", value: id });
        break;
      default:
        break;
    }
  };

  const onDomicilioChange = ({ value, name }: { value: any; name: string }) => {
    getOnChange(name, value.target.value);
  };

  return (
    <form>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={12}>
          <CustomTextField
            id="cuit"
            name="cuit"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.cuit}
            onChange={onChange}
            required
            label="CUIT sin guiones ni espacios"
            pattern={cuitRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            id="nombre"
            variant="filled"
            name="nombre"
            pattern={alphanumericRegex}
            required
            style={{ width: "100%" }}
            defaultValue={formValues.nombre}
            onChange={onChange}
            label="Nombre"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            id="nombreLegal"
            name="nombreLegal"
            variant="filled"
            pattern={alphanumericRegex}
            style={{ width: "100%" }}
            required
            defaultValue={formValues.nombreLegal}
            onChange={onChange}
            label="Nombre Legal"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomTextField
            id="telefono"
            label="Telefono"
            name="telefono"
            pattern={numericRegex}
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.telefono}
            onChange={onChange}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomTextField
            id="correo"
            name="correo"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.correo}
            onChange={onChange}
            required
            label="Email"
            pattern={emailRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <CustomTextField
            id="calle"
            name="calle"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.calle}
            pattern={alphanumericRegex}
            required
            onChange={onChange}
            label="Calle"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            id="numero"
            name="numero"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.numero}
            onChange={onChange}
            pattern={numericRegex}
            required
            label="Numero"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            id="piso"
            name="piso"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.piso}
            onChange={onChange}
            pattern={numericRegex}
            label="Piso"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            id="departamento"
            name="departamento"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.departamento}
            onChange={onChange}
            label="Departamento"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            id="codigoPostal"
            variant="filled"
            name="codigoPostal"
            style={{ width: "100%" }}
            defaultValue={formValues.codigoPostal}
            onChange={onChange}
            label="Codigo postal"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="provincia-select-label">Provincia *</InputLabel>
            <StyledSelect
              labelId="provincia-select-label"
              id="provinciaId"
              name="provinciaId"
              required
              value={formValues.provinciaId}
              disabled={!editionMode}
              onChange={(e) =>
                onDomicilioChange({ value: e, name: "provinciaId" })
              }
            >
              {organizationsData.provinces &&
                organizationsData.provinces.length > 0 &&
                organizationsData.provinces.map((value: any) => {
                  return <MenuItem value={value.id}>{value.nombre}</MenuItem>;
                })}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="ciudad-select-label">Ciudad *</InputLabel>
            <StyledSelect
              labelId="ciudad-select-label"
              id="ciudadId"
              name="ciudadId"
              value={formValues.ciudadId}
              disabled={!editionMode}
              required
              onChange={(e) =>
                onDomicilioChange({ value: e, name: "ciudadId" })
              }
            >
              {organizationsData.cities &&
                organizationsData.cities.length > 0 &&
                organizationsData.cities.map((value: any) => {
                  return <MenuItem value={value.id}>{value.nombre}</MenuItem>;
                })}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="localidad-select-label">Localidad *</InputLabel>
            <StyledSelect
              labelId="localidad-select-label"
              id="localidadId"
              name="localidadId"
              required
              value={formValues.localidadId}
              disabled={!editionMode}
              onChange={(e) =>
                onDomicilioChange({ value: e, name: "localidadId" })
              }
            >
              {organizationsData &&
                organizationsData.localities.length > 0 &&
                organizationsData.localities.map((value: any) => {
                  return <MenuItem value={value.id}>{value.nombre}</MenuItem>;
                })}
            </StyledSelect>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default CompanyFormGridComponent;
