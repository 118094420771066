import {
  alphanumericRegex,
  alphanumericWithPointRegex,
} from "../../../../shared/regexPatterns";

export const validatorValues = [
  { name: "organizacionId", required: false },
  {
    name: "tipoId",
    nameToShow: "tipo",
    required: true,
  },
  {
    name: "tipoRedId",
    nameToShow: "red",
    required: true,
  },
  {
    name: "numero",
    nameToShow: "número de lanza",
    required: false,
    pattern: alphanumericRegex,
    regexMessage: "solo acepta formato alfanumerico",
  },

  {
    name: "identificador",
    nameToShow: "eui",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "version",
    pattern: alphanumericWithPointRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "nombre",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  { name: "estadoId", required: false },
];
