import React, { CSSProperties } from "react";
import { verifyCuit } from "../../../shared/validations";
import { StyledTextField } from "./StyledTextField";

interface TextFieldProps {
  name: string;
  id: string;
  defaultValue?: any;
  variant?: "filled" | "standard" | "outlined";
  style?: CSSProperties;
  className?: string;
  onChange?: (e: { value: any; name: string }) => void;
  required?: boolean;
  label?: string;
  placeholder?: string;
  pattern?: RegExp;
  disabled?: boolean;
}

const CustomTextField = (props: TextFieldProps) => {
  const [localValue, setLocalValue] = React.useState(props.defaultValue);
  const [error, setError] = React.useState(false);

  const { disabled, pattern, onChange, defaultValue, ...textProps } = props;

  const handleValidation = async (value: string) => {
    if (pattern) {
      let regex;
      if (textProps.name === "cuit") {
        regex = await verifyCuit(value);
      } else {
        regex = pattern.test(value);
      }
      setError(!regex);
    }
  };

  React.useEffect(() => {
    if (defaultValue) {
      setLocalValue(defaultValue);
      onChange && onChange({ value: defaultValue, name: props.name });
    }
  }, [defaultValue]);

  const handleChange = (e: any) => {
    setLocalValue(e.target.value);
    onChange && onChange({ value: e.target.value, name: props.name });
  };

  const onBlur = (e: any) => {
    if (textProps.required || localValue) {
      handleValidation(e.target.value);
    }
  };

  return (
    <div>
      <StyledTextField
        {...textProps}
        value={localValue}
        error={error}
        onBlur={onBlur}
        onChange={handleChange}
        inputProps={{ pattern }}
        disabled={disabled}
      />
    </div>
  );
};

export default CustomTextField;
