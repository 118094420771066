import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import backgrounIOFCompany from "../../assets/img/LoginPage/backgrounIOFCompany.svg";
import IOFLogo from "../../assets/icons/svg/LoginPage/logo-white.svg";

import { useNavigate } from "react-router-dom";
import { ReduxType } from "../../shared/types";
import { Paths } from "../../config/paths";
import Grid from "@mui/material/Grid";

const LoginLayaout = (props: any) => {
  const [show, setShow] = useState(false);
  const [errorType, setErrorType] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const { children, width } = props;
  const navigate = useNavigate();

  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (userData.error && userData.error.message) {
      setAndShowError(userData.error.message, true);
    }
  }, [userData.error]);

  useEffect(() => {
    if (userData.userReset) {
      setAndShowError(userData.userReset, false);
      navigate(Paths.LOGIN);
    }
  }, [userData.userReset]);

  const setAndShowError = (error: any, errorType: boolean) => {
    setErrorType(errorType);
    setErrorMessage(error);
    toggleShow();
  };

  const toggleShow = () => setShow(!show);

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={7}>
          <span
            style={{
              position: "fixed",
              marginTop: width <= 768 ? "10vh" : "25vh",
              marginLeft: width <= 768 ? "0vh" : "20vh",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid>
                <img
                  src={IOFLogo}
                  alt={"Login-logo"}
                  style={{
                    height: 96,
                    width: "213.23",
                  }}
                />
              </Grid>
            </Grid>
            <span
              style={{
                position: "fixed",
                marginTop: width <= 768 ? "10vh" : "0vh",
              }}
            >
              {children}
            </span>
          </span>
        </Grid>
        <Grid
          item
          xs={5}
          style={{
            height: width <= 768 ? "30vh" : "100vh",
            backgroundSize: "cover",
            backgroundImage: `url(${backgrounIOFCompany})`,
          }}
        ></Grid>
      </Grid>
    </>
  );
};

export default LoginLayaout;
