import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL } from "../../config/general-config";
import { apiGet } from "../../shared/ApiService";
import { ApiResponse } from "../../shared/types";
import { getHarvestsListDataFail, getHarvestsListDataSuccess } from "./actions";
import { GET_HARVESTS_LIST_DATA } from "./actionTypes";

function* onGetHarvestsListData() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/api/${Endpoints.HARVESTS}`,
        })
      );
    });
    yield put(getHarvestsListDataSuccess(response));
  } catch (error: any) {
    yield put(getHarvestsListDataFail(error));
  }
}

function* HarvestsSaga() {
  yield takeLatest(GET_HARVESTS_LIST_DATA, onGetHarvestsListData);
}

export default HarvestsSaga;
