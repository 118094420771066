import { formatDate } from "../../../shared/helper";

interface Column {
  id: "id" | "cuit" | "nombre" | "createdAt" | "compania" | "acciones";
  label: string;
  minWidth?: number;
  align?: "right";
  render?: (data: any, record: any) => JSX.Element;
  format?: (value: number) => string;
}

export const columns: readonly Column[] = [
  { id: "id", label: "ID", minWidth: 40 },
  { id: "cuit", label: "CUIT", minWidth: 100 },
  {
    id: "nombre",
    label: "Nombre",
    minWidth: 170,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "createdAt",
    label: "Fecha de creación",
    minWidth: 100,
    format: (value: any) => formatDate(value) || "",
  },
  {
    id: "compania",
    label: "Compañías",
    minWidth: 170,
  },
  {
    id: "acciones",
    label: "Acciones",
    minWidth: 95,
  },
];
