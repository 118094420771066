import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  isSubmitting?: boolean;
  children?: React.ReactNode;
  submitName?: string;
  onClose: () => void;
  handleSubmit: (e: any) => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const {
    children,
    submitName,
    handleSubmit,
    onClose,
    isSubmitting,
    ...other
  } = props;

  return (
    <DialogTitle
      {...other}
      style={{
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 16px",
      }}
    >
      {children}
      <div style={{ display: "inline-flex", gap: 10, alignItems: "center" }}>
        <Button
          className="login-button"
          style={{ marginLeft: -10 }}
          variant="text"
          color="info"
          onClick={onClose}
          key="cancel-modal"
        >
          Cancelar
        </Button>
        <Button
          className="login-button ligth-green "
          style={{ margin: "auto" }}
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}
          key="submit-modal"
        >
          {isSubmitting ? (
            <CircularProgress
              style={{ width: 24, height: 24 }}
              className="color-white"
            />
          ) : submitName ? (
            submitName
          ) : (
            `Guardar`
          )}
        </Button>
      </div>
    </DialogTitle>
  );
};

export interface CustomizedProps extends DialogProps {
  title: string;
  isSubmitting?: boolean;
  submitName?: string;
  handleClose: () => void;
}

export default function CustomizedDialogs(props: CustomizedProps) {
  const {
    handleClose,
    open,
    onSubmit,
    title,
    children,
    submitName,
    isSubmitting,
  } = props;
  const handleSubmit = (e: any) => {
    onSubmit && onSubmit(e);
  };
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        submitName={submitName}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      >
        <span className={`modal-main-title fw-5`}>{title}</span>
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </BootstrapDialog>
  );
}
