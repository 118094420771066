export const GET_DEVICES_LIST_DATA = "GET_DEVICES_LIST_DATA";
export const GET_DEVICES_LIST_DATA_FAIL = "GET_DEVICES_LIST_DATA_FAIL";
export const GET_DEVICES_LIST_DATA_SUCCESS = "GET_DEVICES_LIST_DATA_SUCCESS";

export const GET_DEVICES_UNLINK_LIST_DATA = "GET_DEVICES_UNLINK_LIST_DATA";
export const GET_DEVICES_UNLINK_LIST_DATA_FAIL =
  "GET_DEVICES_UNLINK_LIST_DATA_FAIL";
export const GET_DEVICES_UNLINK_LIST_DATA_SUCCESS =
  "GET_DEVICES_UNLINK_LIST_DATA_SUCCESS";

export const LINK_OR_UNLINK_ORGANIZATION = "LINK_OR_UNLINK_ORGANIZATION";
export const LINK_OR_UNLINK_ORGANIZATION_FAIL =
  "LINK_OR_UNLINK_ORGANIZATION_FAIL";
export const LINK_OR_UNLINK_ORGANIZATION_SUCCESS =
  "LINK_OR_UNLINK_ORGANIZATION_SUCCESS";

export const FIND_DEVICE_STATES = "FIND_DEVICE_STATES";
export const FIND_DEVICE_STATES_FAIL = "FIND_DEVICE_STATES_FAIL";
export const FIND_DEVICE_STATES_SUCCESS = "FIND_DEVICE_STATES_SUCCESS";

export const FIND_DEVICE_TYPES = "FIND_DEVICE_TYPES";
export const FIND_DEVICE_TYPES_FAIL = "FIND_DEVICE_TYPES_FAIL";
export const FIND_DEVICE_TYPES_SUCCESS = "FIND_DEVICE_TYPES_SUCCESS";

export const FIND_DEVICE_NETWORKS = "FIND_DEVICE_NETWORKS";
export const FIND_DEVICE_NETWORKS_FAIL = "FIND_DEVICE_NETWORKS_FAIL";
export const FIND_DEVICE_NETWORKS_SUCCESS = "FIND_DEVICE_NETWORKS_SUCCESS";

export const CREATE_DEVICE_ACTION = "CREATE_DEVICE_ACTION";
export const CREATE_DEVICE_FAIL = "CREATE_DEVICE_FAIL";
export const CREATE_DEVICE_SUCCESS = "CREATE_DEVICE_SUCCESS";

export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const UPDATE_DEVICE_FAIL = "UPDATE_DEVICE_FAIL";
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS";
