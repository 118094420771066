import { CSSProperties } from "react";
import { formatDate } from "../../../shared/helper";
import { DeviceColumnProps } from "../../../shared/types";

const state = {
  id: "estado",
  label: "Estado",
  minWidth: 30,
  render: (value: any, record: any) => {
    const activeStyle: CSSProperties = {
      padding: 5,
      background: "rgba(139, 195, 74, 0.2)",
      borderRadius: 3,
      textAlign: "center",
      color: "#6BAA00",
    };
    const inactiveStyle: CSSProperties = {
      color: "#C3604A",
      background: "rgba(195, 96, 74, 0.2)",
      textAlign: "center",
      padding: 5,
      borderRadius: 3,
    };
    return (
      <div style={value === "Deshabilitado" ? inactiveStyle : activeStyle}>
        {value === "Deshabilitado" ? "Broken" : "Ok"}
      </div>
    );
  },
};

export const organizationDeviceModalColumns: readonly any[] = [
  {
    id: "checkbox",
    label: "",
    idValue: "id",
  },
  { id: "id", label: "ID", width: 50 },
  {
    id: "tipo",
    label: "Tipo",
    width: 90,
  },
  {
    id: "nombre",
    label: "Nombre",
    width: 90,
  },
  {
    id: "identificador",
    label: "EUI",
    width: 100,
  },
  { ...state },
];

export const defaultColumns: readonly DeviceColumnProps[] = [
  {
    id: "checkbox",
    label: "",
    idValue: "id",
  },
  { id: "id", label: "ID", width: 50 },
  {
    id: "nombre",
    label: "Nombre",
    width: 90,
  },
  {
    id: "tipo",
    label: "Tipo",
    width: 90,
  },
  {
    id: "numero",
    label: "Número de lanza",
    width: 100,
  },
  {
    id: "identificador",
    label: "EUI",
    width: 100,
  },
  {
    id: "version",
    label: "Version",
    width: 80,
  },
  {
    id: "organizacion",
    label: "Organizacion",
    width: 100,
  },
  {
    id: "estado",
    label: "Estado",
    width: 30,
    render: (value: any, record: any) => {
      const activeStyle: CSSProperties = {
        padding: 5,
        background: "rgba(139, 195, 74, 0.2)",
        borderRadius: 3,
        textAlign: "center",
        color: "#6BAA00",
      };
      const inactiveStyle: CSSProperties = {
        color: "#C3604A",
        background: "rgba(195, 96, 74, 0.2)",
        textAlign: "center",
        padding: 5,
        borderRadius: 3,
      };
      return (
        <div style={value === "Deshabilitado" ? inactiveStyle : activeStyle}>
          {value === "Deshabilitado" ? "Broken" : "Ok"}
        </div>
      );
    },
  },
  {
    id: "network_server",
    label: "Network Server",
    width: 100,
    align: "center",
  },
  {
    id: "createdAt",
    label: "F.Creación",
    width: 170,
    applyFormat: true,
    format: (value: number) => formatDate(value),
  },
  {
    id: "acciones",
    label: "Acciones",
    width: 90,
    format: (value: number) => value.toFixed(2),
  },
];
