import { ThemeProvider, createTheme, ThemeOptions } from "@mui/material";

import React from "react";

export interface AppThemeProps {
  children: JSX.Element;
  theme?: ThemeOptions;
}

export const AppThemeProvider = (props: AppThemeProps) => {
  const theme = createTheme({
    ...props.theme,
    typography: {
      ...props.theme?.typography,
      fontFamily: '"Poppins", sans-serif ',
    },
  });
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
