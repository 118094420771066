import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React from "react";
import {
  alphanumericRegex,
  alphanumericWithPointRegex,
} from "../../../../shared/regexPatterns";
import { CustomTextField } from "../../../formInputs";
import { StyledSelect } from "../../../formInputs/SelectComponent/StyledSelect";

export interface FormGridProps {
  states: any[];
  networks: any[];
  types: any[];
  organizations: any[];
  editionMode?: boolean;
  onChange: ({ value, name }: { value: any; name: string }) => void;
  formValues: any;
  organizationId?: number;
  fromOrganization?: boolean;
}

const FormGridComponent = (props: FormGridProps) => {
  const {
    onChange,
    formValues,
    fromOrganization,
    editionMode,
    networks,
    types,
    organizations,
    organizationId,
  } = props;

  return (
    <form>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        {!fromOrganization && (
          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="provincia-select-label">Organización</InputLabel>
              <StyledSelect
                labelId="provincia-select-label"
                id="organizacionId"
                name="organizacionId"
                value={
                  organizationId
                    ? organizationId
                    : formValues.organizacionId !== null
                    ? formValues.organizacionId
                    : ""
                }
                onChange={(e) =>
                  onChange({ value: e.target.value, name: "organizacionId" })
                }
                required
                disabled={!editionMode || !!organizationId}
              >
                {organizations &&
                  organizations.length > 0 &&
                  organizations.map((o, index: number) => {
                    return (
                      <MenuItem key={`select-org-${index}`} value={o.id}>
                        {o.nombre}
                      </MenuItem>
                    );
                  })}
              </StyledSelect>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="tipo-select-label">Tipo *</InputLabel>
            <StyledSelect
              required
              id="tipoId"
              name="tipoId"
              labelId="tipo-select-label"
              defaultValue={
                formValues.tipoId
                  ? formValues.tipoId
                  : formValues.dispositivo_tipo
                  ? formValues.dispositivo_tipo.id
                  : ""
              }
              disabled={!editionMode}
              onChange={(e) =>
                onChange({ value: e.target.value, name: "tipoId" })
              }
            >
              {types &&
                types.length > 0 &&
                types.map((t, index: number) => {
                  return (
                    <MenuItem key={`select-type-${index}`} value={t.id}>
                      {t.nombre}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="red-select-label">Red * </InputLabel>
            <StyledSelect
              labelId="red-select-label"
              id="tipoRedId"
              name="tipoRedId"
              defaultValue={formValues.tipoRedId ? formValues.tipoRedId : ""}
              onChange={(e) =>
                onChange({ value: e.target.value, name: "tipoRedId" })
              }
              required
              disabled={!editionMode}
            >
              {networks &&
                networks.length > 0 &&
                networks.map((n, index: number) => {
                  return (
                    <MenuItem key={`select-type-network-${index}`} value={n.id}>
                      {n.nombre}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={4}>
          <CustomTextField
            id="numero"
            name="numero"
            label="Número de lanza"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.numero}
            onChange={onChange}
            pattern={alphanumericRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            required
            id="identificador"
            name="identificador"
            label="EUI"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.identificador}
            onChange={onChange}
            pattern={alphanumericRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            required
            id="version"
            name="version"
            label="Versión"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.version}
            onChange={onChange}
            pattern={alphanumericWithPointRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            required
            id="nombre"
            name="nombre"
            label="Nombre"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.nombre}
            onChange={onChange}
            pattern={alphanumericRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.estadoId}
                disabled={!editionMode}
                onChange={(e) =>
                  onChange({ value: !formValues.estadoId, name: "estadoId" })
                }
              />
            }
            label="Activo"
            labelPlacement="start"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default FormGridComponent;
