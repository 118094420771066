import React from "react";
import { Provider } from "react-redux";
import { RouterConfig } from "./core/routes/RouterConfig";
import store from "./store";

import "./styles/index.css";
import { AppThemeProvider } from "./core/theme/ThemeProvider";

function App() {
  return (
    <React.Suspense fallback="Cargando">
      <AppThemeProvider>
        <Provider store={store}>
          <RouterConfig />
        </Provider>
      </AppThemeProvider>
    </React.Suspense>
  );
}

export default App;
