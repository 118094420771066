import { combineReducers } from "redux";
import DashboardReducer from "./dashboard/reducer";
import UserReducer from "./users/reducer";
import OrganizationsReducer from "./organizations/reducer";
import DeviceReducer from "./devices/reducer";
import RolesReducer from "./roles/reducer";
import HarvestsReducer from "./harvests/reducer";

const rootReducer = combineReducers({
  dashboardData: DashboardReducer,
  userData: UserReducer,
  organizationsData: OrganizationsReducer,
  rolesData: RolesReducer,
  deviceData: DeviceReducer,
  harvestsData: HarvestsReducer
});

export default rootReducer;
