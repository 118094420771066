import { UserLoginRequest } from "../../shared/types";
import * as types from "./actionTypes";

export const loginUserAction = (user: UserLoginRequest) => {
  return {
    type: types.LOGIN_USER,
    ...user,
  };
};

export const loginUserFail = (error: any) => {
  return {
    type: types.LOGIN_USER_FAIL,
    payload: error,
  };
};

export const loginUserSuccess = (user: any) => {
  return {
    type: types.LOGIN_USER_SUCCESS,
    payload: user,
  };
};

export const getUsersListAction = () => {
  return {
    type: types.GET_USERS_LIST,
  };
};

export const getUsersListFail = (error: any) => {
  return {
    type: types.GET_USERS_LIST_FAIL,
    payload: error,
  };
};

export const getUsersListSuccess = (dashboardList: any) => {
  return {
    type: types.GET_USERS_LIST_SUCCESS,
    payload: dashboardList,
  };
};

export const loginUserProfileAction = () => {
  return {
    type: types.LOGIN_PROFILE_USER,
  };
};

export const loginUserProfileFail = (error: any) => {
  return {
    type: types.LOGIN_PROFILE_FAIL,
    payload: error,
  };
};

export const loginUserProfileSuccess = (user: any) => {
  return {
    type: types.LOGIN_PROFILE_SUCCESS,
    payload: user,
  };
};

export const cleanStateAction = () => {
  return {
    type: types.CLEAN_USER,
  };
};

export const cleanStateActionFail = (error: any) => {
  return {
    type: types.CLEAN_USER_FAIL,
    payload: error,
  };
};

export const cleanStateActionSuccess = () => {
  return {
    type: types.CLEAN_USER_SUCCESS,
  };
};

export const createUserAction = (payload: any) => {
  return {
    type: types.CREATE_USER,
    payload,
  };
};

export const createUserFail = (error: any) => {
  return {
    type: types.CREATE_USER_FAIL,
    payload: error,
  };
};

export const createUserSuccess = (response: any) => {
  return {
    type: types.CREATE_USER_SUCCESS,
    payload: response,
  };
};

export const updateUserAction = (userId: number, payload: any) => {
  return {
    type: types.UPDATE_USER,
    userId,
    payload,
  };
};

export const updateUserFail = (error: any) => {
  return {
    type: types.UPDATE_USER_FAIL,
    payload: error,
  };
};

export const updateUserSuccess = (response: any) => {
  return {
    type: types.UPDATE_USER_SUCCESS,
    payload: response,
  };
};

export const deleteUserAction = (userId: number) => {
  return { type: types.DELETE_USER, userId };
};

export const deleteUserFail = (error: any) => {
  return { type: types.DELETE_USER_FAIL, payload: error };
};

export const deleteUserSuccess = (payload: any) => {
  return { type: types.DELETE_USER_SUCCESS, payload };
};

export const cleanCreateOrUpdateResponseAction = () => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE };
};

export const cleanCreateOrUpdateResponseFail = (error: any) => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL, payload: error };
};

export const cleanCreateOrUpdateResponseSuccess = () => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS };
};
