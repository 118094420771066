import * as _ from "lodash";
import moment from "moment";
import { Paths } from "../config/paths";
import { ScreenPermissions, ScreensEnum } from "./types";

const asyncLocalStorage = {
  setItem: function (key: string, value: string) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
};

const findIndex = (arrayElements: any, key: string, match: string | number) => {
  return _.findIndex(arrayElements, (i: any) => {
    return i[key] === match;
  });
};

const formatDate = (date: any) => {
  if (!date || date === "") return;
  return moment(date).format("DD/MM/YYYY");
};

const alertColor = (alertColor: string) => {
  switch (alertColor) {
    case "yellow":
      return "#FFDD00";
    case "orange":
      return "#FD7E14";
    case "red":
      return "#FF0000";
    case "green":
      return "#6BAA00";
  }
  return "";
};

const DashboardMenu = [
  ScreensEnum.ORGANIZACIONES,
  ScreensEnum.DISPOSITIVOS,
  ScreensEnum.USUARIOS,
  ScreensEnum.ROLES,
  ScreensEnum.COSECHAS,
  ScreensEnum.REPORTE,
];

const getEquivalent = (path: Paths | ScreensEnum) => {
  switch (path) {
    case "/organizaciones":
    case "ORGANIZATION":
      return ScreenPermissions.ORGANIZATION;

    case "/NuevaOrganizacion":
    case "NEW_ORGANIZATION":
      return ScreenPermissions.ORGANIZATION;

    case "/Usuarios":
    case "USERS":
      return ScreenPermissions.USER;

    case "/Roles":
    case "ROLES":
      return ScreenPermissions.ROL;

    case "/Dispositivos":
    case "DEVICES":
      return ScreenPermissions.DEVICES;
    case "/Cosechas":
    case "COSECHAS":
      return ScreenPermissions.COSECHAS;

    default:
      return "NO_EQUIVALENT";
  }
};

const cloneWithoutReference = (elements: any) => {
  return JSON.parse(JSON.stringify(elements));
};

const getOrganizationTitle = (
  title: "company" | "device" | "establishment" | "user",
  mode: "create" | "edit" | "view" | "link"
) => {
  let mainTitle = "";
  let submitButton = "Guardar";
  if (mode === "view") submitButton = "Editar";

  switch (title) {
    case "company":
      mainTitle = "Nueva compañía";
      if (mode === "edit") mainTitle = "Editar compañía";
      if (mode === "view") mainTitle = "Compañía";
      break;
    case "device":
      mainTitle = "Nuevo dispositivo";
      if (mode === "link") {
        mainTitle = "Vincular dispositivo";
        submitButton = "Vincular";
      }
      if (mode === "view") mainTitle = "Dispositivo";
      if (mode === "edit") mainTitle = "Editar dispositivo";
      break;
    case "establishment":
      mainTitle = "Nuevo establecimiento";
      if (mode === "edit") mainTitle = "Editar establecimiento";
      if (mode === "view") mainTitle = "Establecimiento";
      break;
    case "user":
      mainTitle = "Nuevo usuario";
      if (mode === "edit") mainTitle = "Editar usuario";
      if (mode === "view") mainTitle = "Usuario";
      break;
  }
  return {
    mainTitle: mainTitle,
    submitButton: submitButton,
  };
};

export {
  asyncLocalStorage,
  DashboardMenu,
  cloneWithoutReference,
  getOrganizationTitle,
  alertColor,
  findIndex,
  formatDate,
  getEquivalent,
};
