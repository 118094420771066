import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Paths } from "../../config/paths";
import DevicePage from "../../pages/device/DevicePage";
import HarvestsPage from "../../pages/harvests/HavestsPage";
import LoginPage from "../../pages/login/LoginPage";
import LogoutPage from "../../pages/logout/LogoutPage";
import NoPermissionsPage from "../../pages/noPermissions/NoPermissions";
import NotFountPage from "../../pages/notFound/NotFountPage";
import NewOrganizationPage from "../../pages/organization/NewOrganizationPage";
import OrganizationPage from "../../pages/organization/OrganizationPage";
import RolePage from "../../pages/role/RolePage";
import UserPage from "../../pages/user/UserPage";

export const RouterConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={Paths.LOGIN} element={<LoginPage />} />
        <Route path={Paths.LOGOUT} element={<LogoutPage />} />
        <Route path={Paths.ROOT} element={<OrganizationPage />} />
        <Route path={Paths.ORGANIZATION} element={<OrganizationPage />} />
        <Route
          path={Paths.NEW_ORGANIZATION}
          element={<NewOrganizationPage />}
        />
        <Route path={Paths.USERS} element={<UserPage />} />

        <Route path={Paths.DEVICES} element={<DevicePage />} />
        <Route path={Paths.ROLES} element={<RolePage />} />
        <Route path={Paths.REPORTS} element={<OrganizationPage />} />
        <Route path={Paths.NOTFOUND} element={<NotFountPage />} />
        <Route path={Paths.COSECHAS} element={<HarvestsPage />} />
        <Route path={Paths.NO_PERMISSIONS} element={<NoPermissionsPage />} />
      </Routes>
    </BrowserRouter>
  );
};
