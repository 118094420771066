import React from "react";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

const NotFountPage = () => {
  return (
    <AuthenticatedRoute withHeader path={Paths.NOTFOUND}>
      NotFountPage
    </AuthenticatedRoute>
  );
};

export default NotFountPage;
