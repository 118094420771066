import { findIndex, getEquivalent } from "./helper";

export const isCRUDdisabled = (
  usersList: any[],
  permissionsList: any[],
  username: string,
  screen: any
) => {
  if (
    !usersList ||
    usersList.length === 0 ||
    !permissionsList ||
    permissionsList.length === 0
  )
    return false;
  const user = usersList.find((val) => val.username === username);
  const permissions = user.usuario_permisos
    .filter((val: any) => val.deshabilitado === 0)
    .map((val: any) => {
      return val.permisoId;
    });
  const userPermissions = permissionsList.filter((val) => {
    return permissions.find((elem: any) => elem === val.id);
  });

  const screenEquivalent = getEquivalent(screen);
  const index = findIndex(userPermissions, "nombre", screenEquivalent);
  return index !== -1;
};
