import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalFormHandlerProps, ReduxType } from "../../../../shared/types";
import {
  findDeviceNetworksAction,
  findDeviceStatesAction,
  findDeviceTypesAction,
  getDeviceUnlinkListDataAction,
} from "../../../../store/devices/actions";
import { getCompaniesDataAction } from "../../../../store/organizations/actions";
import { getRolesListAction } from "../../../../store/roles/actions";
import { DeviceListComponent } from "../../device";
import { DeviceFormGridComponent } from "../../newDevice/deviceFormGrid";
import { EstablishmentFormGridComponent } from "../../newEstablishment/establishmentFormGrid";
import { UserFormGridComponent } from "../../newUser/userFormGrid";
import { CompanyFormGridComponent } from "../companyFormGrid";

const ModalFormHandler = (props: ModalFormHandlerProps) => {
  const {
    formValues,
    editionMode,
    name,
    createMode,
    linkMode,
    viewMode,
    onChange,
    organizationId,
    selectedFromOrgRows,
    setSelectedFromOrgRows,
  } = props;

  const dispatch = useDispatch();

  const { deviceData, organizationsData, rolesData } = useSelector(
    (state: ReduxType) => {
      return state;
    }
  );

  const getCompaniesList = async () => {
    await dispatch(getCompaniesDataAction());
  };

  useEffect(() => {
    if (!organizationId) getCompaniesList();
  }, [organizationId]);

  useEffect(() => {
    switch (name) {
      case "device":
        getAllDeviceData();
        break;
      case "company":
        break;
      case "establishment":
        break;
      case "user":
        getAllUserData();
        break;
      default:
        break;
    }
  }, [name]);

  useEffect(() => {
    if (!deviceData.devicesUnlink) {
      getUnlinkDeviceList();
    }
  }, [deviceData.devicesUnlink]);

  const getUnlinkDeviceList = async () => {
    await dispatch(getDeviceUnlinkListDataAction());
  };

  const getAllUserData = async () => {
    await dispatch(getRolesListAction());
  };

  const getAllDeviceData = async () => {
    await dispatch(findDeviceStatesAction());
    await dispatch(findDeviceTypesAction());
    await dispatch(findDeviceNetworksAction());
  };

  const isNewOrganization = !organizationId
    ? organizationsData.noOrganizationCompaniesList
    : organizationsData.organizationsList;

  switch (name) {
    case "company":
      return (
        <CompanyFormGridComponent
          formValues={formValues}
          onChange={onChange}
          editionMode={editionMode!}
        />
      );
    case "device":
      if (createMode || editionMode || viewMode) {
        return (
          <DeviceFormGridComponent
            fromOrganization
            organizationId={organizationId}
            organizations={isNewOrganization}
            states={deviceData.states}
            networks={deviceData.networks}
            types={deviceData.types}
            formValues={formValues}
            onChange={onChange}
            editionMode={editionMode!}
          />
        );
      } else {
        return (
          <DeviceListComponent
            fromOrganization
            organizationId={organizationId}
            selectedFromOrgRows={selectedFromOrgRows}
            setSelectedFromOrgRows={setSelectedFromOrgRows}
          />
        );
      }
    case "user":
      return (
        <UserFormGridComponent
          rolesList={rolesData.rolesList || []}
          createMode={createMode!}
          handlePermissionDelete={async () => {}}
          organizations={isNewOrganization}
          onChange={onChange}
          organizationId={organizationId}
          editionMode={editionMode}
          formValues={formValues}
        />
      );
    case "establishment":
      return (
        <EstablishmentFormGridComponent
          organizations={isNewOrganization}
          organizationId={organizationId}
          editionMode={editionMode!}
          formValues={formValues}
          onChange={onChange}
        />
      );

    default:
      break;
  }
  return <></>;
};

export default ModalFormHandler;
