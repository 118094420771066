import React, { useState } from "react";

import { CustomLinkProps, ReduxType } from "../../../shared/types";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrganizationAction,
  getOrganizationsListDataAction,
  cleanOrganizationDataAction,
} from "../../../store/organizations/actions";
import { GenericGrid } from "../genericGrid";
import { ToastComponent } from "../../toast";
import { columns } from "./Columns";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "../../../config/paths";

const OrganizationListComponent = (props: any) => {
  const [showToast, setShowToast] = useState(false);
  const [localMessage, setLocalMessage] = useState<any>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  const { organizationsList } = organizationsData;

  const { state }: any = useLocation();

  React.useEffect(() => {
    if (state && state.message && localMessage !== state.message) {
      setLocalMessage(state.message);
      setShowToast(true);
    }
  }, []);

  React.useEffect(() => {
    getOrganizationsList();
  }, []);

  const getOrganizationsList = async () => {
    await dispatch(getOrganizationsListDataAction());
    await dispatch(cleanOrganizationDataAction());
  };

  const headerButtons: CustomLinkProps[] = [
    {
      onClick: () => {
        navigate(Paths.NEW_ORGANIZATION, {
          state: {
            createMode: true,
            editionMode: true,
          },
        });
      },

      id: "newOrganization",
      label: "Nueva organización",
      type: "SIMPLE",
      className: "login-button ligth-green",
      variant: "contained",
    },
  ];

  const onActionClick = async (
    action: "VIEW" | "DELETE" | "EDIT",
    record: any
  ) => {
    switch (action) {
      case "EDIT":
        navigate(Paths.NEW_ORGANIZATION, {
          state: {
            editMode: true,
            record: {
              ...record,
              calle: record.domicilio.calle,
              numero: record.domicilio.calle,
              piso: record.domicilio.piso,
              departamento: record.domicilio.departamento,
              codigoPostal: record.domicilio.codigoPostal,
              ciudadId: record.domicilio.localidad.ciudadId,
              provinciaId: record.domicilio.localidad.ciudad.provinciaId,
              localidadId: record.domicilio.localidad.id,
            },
          },
        });
        break;
      case "VIEW":
        navigate(Paths.NEW_ORGANIZATION, {
          state: {
            viewMode: true,
            record: {
              ...record,
              calle: record.domicilio.calle,
              numero: record.domicilio.numero,
              piso: record.domicilio.piso,
              departamento: record.domicilio.departamento,
              codigoPostal: record.domicilio.codigoPostal,
              ciudadId: record.domicilio.localidad.ciudadId,
              provinciaId: record.domicilio.localidad.ciudad.provinciaId,
              localidadId: record.domicilio.localidad.id,
            },
          },
        });
        break;
      case "DELETE":
        await dispatch(deleteOrganizationAction(record.id));
        await getOrganizationsList();
        break;
      default:
        break;
    }
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setLocalMessage(undefined);
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={false}
        bodyContent={localMessage}
      />
      <GenericGrid
        headerButtons={headerButtons}
        columns={columns}
        displayData={organizationsList}
        searchCallback={(val: any, value: any) =>
          val.id === value ||
          (val.cuit && val.cuit.toUpperCase().indexOf(value) > -1) ||
          (val.nombre && val.nombre.toUpperCase().indexOf(value) > -1)
        }
        header={true}
        hasSearchInput={true}
        onActionClick={onActionClick}
      />
    </>
  );
};

export default OrganizationListComponent;
