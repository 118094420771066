import { alphanumericRegex } from "../../../../shared/regexPatterns";

export const validatorValues = [
  {
    name: "nombre",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "rol_permisos",
    nameToShow: "permisos",
    required: false,
  },
];
