import * as types from "./actionTypes";

export const getDevicesListDataAction = () => {
  return {
    type: types.GET_DEVICES_LIST_DATA,
  };
};

export const getDevicesListDataFail = (error: any) => {
  return { type: types.GET_DEVICES_LIST_DATA_FAIL, payload: error };
};

export const getDevicesListDataSuccess = (dashboardList: any) => {
  return {
    type: types.GET_DEVICES_LIST_DATA_SUCCESS,
    payload: dashboardList,
  };
};

export const getDeviceUnlinkListDataAction = () => {
  return {
    type: types.GET_DEVICES_UNLINK_LIST_DATA,
  };
};

export const getDeviceUnlinkListDataFail = (error: any) => {
  return { type: types.GET_DEVICES_UNLINK_LIST_DATA_FAIL, payload: error };
};

export const getDeviceUnlinkListDataSuccess = (dashboardList: any) => {
  return {
    type: types.GET_DEVICES_UNLINK_LIST_DATA_SUCCESS,
    payload: dashboardList,
  };
};

export const linkOrUnlinkOrganizationAction = (payload: any) => {
  return {
    type: types.LINK_OR_UNLINK_ORGANIZATION,
    payload,
  };
};

export const linkOrUnlinkOrganizationFail = (error: any) => {
  return { type: types.LINK_OR_UNLINK_ORGANIZATION_FAIL, payload: error };
};

export const linkOrUnlinkOrganizationSuccess = (response: any) => {
  return {
    type: types.LINK_OR_UNLINK_ORGANIZATION_SUCCESS,
    payload: response,
  };
};

export const findDeviceStatesAction = () => {
  return {
    type: types.FIND_DEVICE_STATES,
  };
};

export const findDeviceStatesFail = (error: any) => {
  return { type: types.FIND_DEVICE_STATES_FAIL, payload: error };
};

export const findDeviceStatesSuccess = (response: any) => {
  return {
    type: types.FIND_DEVICE_STATES_SUCCESS,
    payload: response,
  };
};

export const findDeviceTypesAction = () => {
  return {
    type: types.FIND_DEVICE_TYPES,
  };
};

export const findDeviceTypesFail = (error: any) => {
  return { type: types.FIND_DEVICE_TYPES_FAIL, payload: error };
};

export const findDeviceTypesSuccess = (response: any) => {
  return {
    type: types.FIND_DEVICE_TYPES_SUCCESS,
    payload: response,
  };
};

export const findDeviceNetworksAction = () => {
  return {
    type: types.FIND_DEVICE_NETWORKS,
  };
};

export const findDeviceNetworksFail = (error: any) => {
  return { type: types.FIND_DEVICE_NETWORKS_FAIL, payload: error };
};

export const findDeviceNetworksSuccess = (response: any) => {
  return {
    type: types.FIND_DEVICE_NETWORKS_SUCCESS,
    payload: response,
  };
};

export const createDeviceAction = (payload: any) => {
  return {
    type: types.CREATE_DEVICE_ACTION,
    payload,
  };
};

export const createDeviceFail = (error: any) => {
  return { type: types.CREATE_DEVICE_FAIL, payload: error };
};

export const createDeviceSuccess = (response: any) => {
  return {
    type: types.CREATE_DEVICE_SUCCESS,
    payload: response,
  };
};

export const updateDeviceAction = (deviceId: number, payload: any) => {
  return {
    type: types.UPDATE_DEVICE,
    deviceId,
    payload,
  };
};

export const updateDeviceFail = (error: any) => {
  return { type: types.UPDATE_DEVICE_FAIL, payload: error };
};

export const updateDeviceSuccess = (response: any) => {
  return {
    type: types.UPDATE_DEVICE_SUCCESS,
    payload: response,
  };
};
