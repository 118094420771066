import { takeLatest, put, call } from "redux-saga/effects";

import {
  CLEAN_CREATE_OR_UPDATE_RESPONSE,
  CLEAN_USER,
  CREATE_USER,
  DELETE_USER,
  GET_USERS_LIST,
  LOGIN_PROFILE_USER,
  LOGIN_USER,
  UPDATE_USER,
} from "./actionTypes";

import {
  cleanCreateOrUpdateResponseFail,
  cleanCreateOrUpdateResponseSuccess,
  cleanStateActionFail,
  cleanStateActionSuccess,
  createUserFail,
  createUserSuccess,
  deleteUserFail,
  deleteUserSuccess,
  getUsersListFail,
  getUsersListSuccess,
  loginUserFail,
  loginUserProfileFail,
  loginUserProfileSuccess,
  loginUserSuccess,
  updateUserFail,
  updateUserSuccess,
} from "./actions";

import { AuthenticateReponse } from "../../core/auth/Type";

import {
  loginWithUsernameAndPassword,
  getProfileUser,
} from "../../core/auth/AuthService";
import { ApiResponse } from "../../shared/types";
import {
  apiDelete,
  apiPost,
  apiPut,
  genericRequest,
} from "../../shared/ApiService";
import { Endpoints } from "../../config/endpoints";
import { API_URL } from "../../config/general-config";

function* onLoginUser(payload: any) {
  try {
    const { username, password } = payload;
    const response: AuthenticateReponse = yield call(() => {
      return Promise.resolve(loginWithUsernameAndPassword(username, password));
    });

    yield put(loginUserSuccess(response));
  } catch (error: any) {
    yield put(loginUserFail(error.message));
  }
}

function* onGetUsersList() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        genericRequest("api", Endpoints.USERS.GET_LIST, "GET", {}, () => {})
      );
    });
    yield put(getUsersListSuccess(response));
  } catch (error) {
    yield put(getUsersListFail(error));
  }
}

function* onLoginProfileUser(payload: any) {
  try {
    const response: AuthenticateReponse = yield call(() => {
      return Promise.resolve(getProfileUser());
    });
    yield put(loginUserProfileSuccess(response));
  } catch (error: any) {
    yield put(loginUserProfileFail(error.message));
  }
}

function* onCleanUserState() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanStateActionSuccess());
  } catch (error: any) {
    yield put(cleanStateActionFail(error.response));
  }
}

function* onCreateUser(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiPost({
          url: `${API_URL}/${Endpoints.USERS.CREATE}`,
          body: action.payload,
        })
      );
    });
    yield put(createUserSuccess(response));
  } catch (error) {
    yield put(createUserFail(error));
  }
}

function* onUpdateUser(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiPut({
          url: `${API_URL}/${Endpoints.USERS.UPDATE}/${action.userId}`,
          body: action.payload,
        })
      );
    });
    yield put(updateUserSuccess(response));
  } catch (error) {
    yield put(updateUserFail(error));
  }
}

function* onDeleteUser(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiDelete({
          url: `${API_URL}/${Endpoints.USERS.CREATE}/${action.userId}`,
        })
      );
    });
    yield put(deleteUserSuccess(response));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

function* onCleanOrUpdateResponseData() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanCreateOrUpdateResponseSuccess());
  } catch (error) {
    yield put(cleanCreateOrUpdateResponseFail(error));
  }
}

function* UserSaga() {
  yield takeLatest(LOGIN_USER, onLoginUser);
  yield takeLatest(LOGIN_PROFILE_USER, onLoginProfileUser);
  yield takeLatest(CLEAN_USER, onCleanUserState);
  yield takeLatest(
    CLEAN_CREATE_OR_UPDATE_RESPONSE,
    onCleanOrUpdateResponseData
  );
  yield takeLatest(GET_USERS_LIST, onGetUsersList);
  yield takeLatest(CREATE_USER, onCreateUser);
  yield takeLatest(UPDATE_USER, onUpdateUser);
  yield takeLatest(DELETE_USER, onDeleteUser);
}

export default UserSaga;
