export const companyFormValues = {
  nombre: "",
  nombreLegal: "",
  cuit: "",
  telefono: "",
  correo: "",
  calle: "",
  numero: "",
  piso: "",
  departamento: "",
  codigoPostal: "",
  provinciaId: "",
  ciudadId: "",
  localidadId: "",
};

export const newOrganizationFormValues = {
  nombre: "",
  nombreLegal: "",
  cuit: "",
  telefono: "",
  correo: "",
  habilitarCadenaBloques: 0,
  seguimientoProsegur: 0,
  calle: "",
  numero: "",
  piso: "",
  departamento: "",
  codigoPostal: "",
  provinciaId: "",
  ciudadId: "",
  localidadId: "",
};

export const newRoleFormValues = {
  rol_permisos: [],
  name: "",
};

export const newUserFormValues = {
  rolId: null,
  phone: null,
  fullname: "",
  dni: "",
  email: "",
  active: false,
};

export const newDeviceFormValues = {
  organizacionId: null,
  tipoId: null,
  tipoRedId: null,
  identificador: "",
  version: "",
  nombre: "",
  orden: 1,
  estadoId: false,
  numero: null,
};

export const newEstablishmentFormValues = {
  organizacionId: null,
  companiaId: null,
  nombre: "",
  longitud: "",
  latitud: "",
  provinciaId: null,
  ciudadId: null,
  localidadId: null,
};
